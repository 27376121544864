import { type PropsWithChildren, useState } from 'react';
import { Group, Stack } from '@mantine/core';
import produce from 'immer';
import type { QueryKey } from '@tanstack/react-query';

import Editor from '@/components/content/rte/Editor';
import MercuryButton from '@/components/content/button/Button';

import { useEdit } from '../context/EditContext';
import { useEditContent } from '../services/editContent';
import { useQueryActions } from '@/hooks/useQueryActions';
import { useToast } from '@/hooks/useToast';

interface EditorWrapperProps {
  initialContent?: string;
  issueId: number;
  endpoint: string;
  queryKey: QueryKey;
}

const EditorWrapper = ({
  initialContent,
  issueId,
  endpoint,
  queryKey,
  children,
}: PropsWithChildren<EditorWrapperProps>) => {
  const [content, setContent] = useState(initialContent ?? '');
  const { isEditing, setIsEditing } = useEdit();
  const { mutate: editContent, isPending: isUpdating } = useEditContent(endpoint, issueId);
  const { createToast } = useToast();
  const { setData } = useQueryActions();

  const updateContent = () => {
    if (!content || !queryKey) return;

    editContent({
      description: content,
    }, {
      onSuccess: () => {
        setData(queryKey, (old: any) => (
          produce(old, (draftState: any) => {
            draftState.description = content;
          })
        ));

        setIsEditing(false);

        createToast('Description Updated', 'success');
      },
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return isEditing ? (
    <Stack gap={15}>
      <Editor
        onChange={setContent}
        content={content}
      />

      <Group gap={10} justify="flex-end">
        <MercuryButton size="compact-md" onClick={updateContent} loading={isUpdating}>
          Save
        </MercuryButton>
        <MercuryButton size="compact-md" onClick={() => setIsEditing(false)} variant="outline" disabled={isUpdating}>
          Cancel
        </MercuryButton>
      </Group>
    </Stack>
  ) : children;
};

export default EditorWrapper;
