import { ComponentType, useState } from 'react';
import { Popover } from '@mantine/core';
import type { Editor } from '@tiptap/react';

// Types
import RTELink from './RTELink';

import classes from '../RTE.module.css';

export interface ToolbarButton {
  icon: ComponentType;
  format: string;
  type?: string;
}

interface ButtonProps extends ToolbarButton {
  editor: Editor | null;
}

const ToolbarButtonComp = ({ editor, type, icon: Icon, format }: ButtonProps) => {
  const [linkBoxOpen, setLinkBoxOpen] = useState(false);

  const listClick = (list: string) => {
    if (list === 'bullet') {
      editor?.chain().toggleBulletList().run();
      return;
    }

    editor?.chain().toggleOrderedList().run();
  };

  const onClick = () => {
    if (type === 'list') {
      listClick(format);
      return;
    }

    if (type === 'link') {
      setLinkBoxOpen(true);
      return;
    }

    if (type === 'unlink') {
      editor?.chain().focus().unsetLink().run();
      return;
    }

    editor?.chain().focus().toggleMark(format).run();
  };

  const isActive = editor?.isActive(format);

  return (
    <div style={{ position: 'relative' }}>
      <Popover
        width={250}
        position="bottom-start"
        arrowPosition="side"
        shadow="md"
        opened={linkBoxOpen}
        withinPortal={false}
        withArrow
        trapFocus
        closeOnClickOutside
      >
        <Popover.Target>
          <button
            aria-label={format}
            className={`${classes.button} ${isActive ? classes.active : ''}`}
            type="button"
            onClick={onClick}
          >
            <Icon />
          </button>
        </Popover.Target>

        <Popover.Dropdown>
          <RTELink setLinkBoxOpen={setLinkBoxOpen} editor={editor} />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default ToolbarButtonComp;
