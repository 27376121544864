import {
  ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';

import { useSamlStart } from '../services/saml';
import { Center, Text } from '@mantine/core';
import Linkv2 from '@/components/content/link/Linkv2';

const SAML_TIMEOUT = 5;
const SAML_INTERVAL = 1000;

interface SamlRedirectProps {
  onCancel: VoidFunction;
  renderMessage: () => ReactNode;
  username: string;
}

const SamlRedirect = ({ onCancel, renderMessage, username }: SamlRedirectProps) => {
  const [count, setCount] = useState(SAML_TIMEOUT);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const { mutate: startSaml, isPending: isRedirecting } = useSamlStart();

  const decrementCount = useCallback(() => {
    setCount((oldTimer) => oldTimer - 1);
  }, []);

  useEffect(() => {
    if (count <= 0) {
      startSaml({ username });
      return undefined;
    }

    const timeoutFunction = setInterval(decrementCount, SAML_INTERVAL);
    intervalRef.current = timeoutFunction;

    return () => clearInterval(timeoutFunction);
  }, [decrementCount, count]);

  return (
    <>
      {renderMessage()}

      <Text ta="center" mt={15} display="block" span>
        Redirecting to single sign-on <b>{count <= 0 ? '...' : `in ${count}`}</b>
      </Text>

      <Center mt={15}>
        <Linkv2 as="button" type="button" onClick={onCancel} disabled={isRedirecting}>
          Cancel Redirection
        </Linkv2>
      </Center>
    </>
  );
};

export default SamlRedirect;
