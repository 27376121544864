import { Flex } from '@mantine/core';

import Acknowledge from '../../metadata/components/Acknowledge';
import Resolve from '../../metadata/components/Resolve';
import RequestTakedown from './RequestTakedown';

import { incidentsKeys } from '../services/keys';
import { dashboardKeys } from '../../dashboard/services/keys';

import { OIncidentsStatus, type IncidentStatuses } from '../types';

interface AlertActionsProps {
  issueId: number;
  isAcknowledged: boolean;
  isResolved: boolean;
  status: IncidentStatuses;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const AlertActions = ({
  asButton,
  issueId,
  isAcknowledged,
  isResolved,
  loading,
  disabled,
  status,
}: AlertActionsProps) => (
  <Flex
    align="center"
    justify="center"
    gap={10}
    flex="1"
    direction={{
      base: asButton ? 'column' : 'row',
      s: 'row',
    }}
  >
    {status !== OIncidentsStatus.Pending && status !== OIncidentsStatus.Resolved && (
      <Acknowledge
        issueId={issueId}
        isAcknowledged={isAcknowledged}
        invalidate={[
          incidentsKeys.issue(issueId),
          incidentsKeys.gfx(incidentsKeys.alerts),
          incidentsKeys.summary(),
          incidentsKeys.table(incidentsKeys.alerts),
          dashboardKeys.alerts(),
        ]}
        loading={loading}
        disabled={disabled}
        asButton={asButton}
      />
    )}

    <Resolve
      issueId={issueId}
      isResolved={isResolved}
      invalidate={[
        incidentsKeys.issue(issueId),
        incidentsKeys.table(incidentsKeys.alerts),
      ]}
      loading={loading}
      disabled={disabled}
      asButton={asButton}
    />

    <RequestTakedown
      issueId={issueId}
      disabled={disabled}
      loading={loading}
      asButton={asButton}
    />
  </Flex>
);

export default AlertActions;
