import { MetisIssueCommonFields } from '@/types/mercury-data-types/metis';
import { parseMetisString } from '@/utils/metisUtils';

export const aggregateFromMetisRelationshipWithAmount = <T extends MetisIssueCommonFields>(
  data: Array<T>, param: keyof T,
) => {
  if (!data || !data.length) return {};

  const result: Record<string, { id: string | number; name: string; amount: number }> = {};

  data.forEach((item) => {
    const value = item[param];

    if (typeof value === 'string') {
      if (!result[value]) {
        result[value] = { id: value, name: parseMetisString(value), amount: 1 };
      } else {
        result[value].amount += 1;
      }
    }

    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (!result[val]) {
          result[val] = { id: val, name: parseMetisString(val), amount: 1 };
        } else {
          result[val].amount += 1;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      const values = value as Record<string, string>;

      Object.keys(values).forEach((key) => {
        if (!result[key]) {
          result[key] = { id: key, name: parseMetisString(values[key]), amount: 1 };
        } else {
          result[key].amount += 1;
        }
      });
    }
  });

  return result;
};

export const aggregateFromMetisRelationship = <T extends MetisIssueCommonFields>(data: Array<T>, param: keyof T) => {
  if (!data || !data.length) return {};

  const result: Record<string, string> = {};

  data.forEach((item) => {
    const value = item[param];

    if (typeof value === 'string') {
      if (!result[value]) {
        result[value] = value;
      }
    } else if (Array.isArray(value)) {
      value.forEach((val) => {
        if (!result[val]) {
          result[val] = val;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      const values = value as Record<string, string>;
      Object.keys(values).forEach((key) => {
        if (!result[key]) {
          result[key] = values[key];
        }
      });
    }
  });

  return result;
};
