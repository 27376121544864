import type { MutableRefObject } from 'react';

import { closeBrackets,
  closeBracketsKeymap,
  completionKeymap } from '@codemirror/autocomplete';
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands';
import { markdown } from '@codemirror/lang-markdown';
import { bracketMatching,
  defaultHighlightStyle,
  indentOnInput,
  syntaxHighlighting } from '@codemirror/language';
import { lintKeymap } from '@codemirror/lint';
import type { Extension } from '@codemirror/state';
import { EditorState } from '@codemirror/state';
import {
  EditorView,
  crosshairCursor,
  drawSelection,
  dropCursor,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  rectangularSelection,
} from '@codemirror/view';
import { theme } from './theme';

const basicSetup: Extension = [
  highlightActiveLineGutter(),
  highlightSpecialChars(),
  history(),
  drawSelection(),
  dropCursor(),
  EditorView.lineWrapping,
  EditorState.allowMultipleSelections.of(true),
  indentOnInput(),
  syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
  bracketMatching(),
  closeBrackets(),
  rectangularSelection(),
  crosshairCursor(),
  highlightActiveLine(),
  keymap.of([
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...historyKeymap,
    ...completionKeymap,
    ...lintKeymap,
  ]),
];

interface StateOptions {
  onChange: (getString: () => string) => void;
  lock: MutableRefObject<boolean>;
  content: string;
}

export const createCodeMirrorState = ({
  onChange,
  lock,
  content,
}: StateOptions) => EditorState.create({
  doc: content,
  extensions: [
    theme(),
    basicSetup,
    markdown(),
    EditorView.updateListener.of((viewUpdate) => {
      if (viewUpdate.focusChanged) {
        // eslint-disable-next-line no-param-reassign
        lock.current = viewUpdate.view.hasFocus;
      }

      if (viewUpdate.docChanged) {
        const getString = () => viewUpdate.state.doc.toString();
        onChange(getString);
      }
    }),
  ],
});

interface ViewOptions extends StateOptions {
  root: HTMLElement;
}

export const createCodeMirrorView = ({ root, ...options }: ViewOptions) => new EditorView({
  state: createCodeMirrorState({ ...options }),
  parent: root,
});
