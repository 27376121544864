import type { TicketSources } from '@/client/features/issue/types';
import type { RedmineEntityField } from '@/types/mercury-data-types';
import { getEntries } from './array';

export const normalizeRedmineArrayProp = (prop: Array<RedmineEntityField> = []) => prop && prop.map((item) => ({
  id: item.instance_id,
  name: item.value,
  disabled: item.disabled,
  amount: item.amount,
}));

export const normalizeTicketSources = (data: TicketSources = []) => data && data.map((source) => ({
  id: Number(source.id_source),
  name: source.name_source,
  disabled: source.disabled,
}));

export const entriesToNormalizedRedmineArray = (entries: Record<string, string> = {}) => (
  getEntries(entries).map(([id, name]) => ({
    id: Number(id),
    name,
  }))
);
