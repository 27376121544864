export const issuesKeys = {
  all: ['issue'] as const,

  comments: (issueId: number) => [...issuesKeys.all, 'comments', issueId] as const,

  metadataHistory: (issueId: number) => [...issuesKeys.all, 'metadatahistory', issueId] as const,

  ticketstablemeta: () => [...issuesKeys.all, 'ticketstable', 'meta'] as const,

  vulnerabilities: (issueId: number) => [...issuesKeys.all, 'vulnerabilities', issueId] as const,
};
