import { Box } from '@mantine/core';

// Components
import TableInstance from '@/components/content/table/TableInstance';

// Hooks
import { useTable } from '@/hooks/useTable';
import { columns } from './ticketTable.table';

import type { Ticket } from '@/types/mercury-data-types';

import tableClasses from '@/components/content/table/Table.module.css';

interface TicketTableProps {
  tickets: Array<Ticket>;
  issue: string | number;
}

const TicketTable = ({ tickets, issue }: TicketTableProps) => {
  const { instanceConfiguration } = useTable({
    columns,
    basic: true,
    namespace: 'tickets',
    initialState: {
      start: 0,
      length: 5,
    },
  });

  return (
    <Box className={tableClasses.autoLayout}>
      <TableInstance
        data={tickets}
        instanceConfiguration={instanceConfiguration}
        id={`ticket-table-${issue}`}
        entries={[5, 10, 15, 20]}
        options={{
          hideToolbar: true,
          noHighlight: true,
        }}
      />
    </Box>
  );
};

export default TicketTable;
