import { useQuery } from '@tanstack/react-query';

import { issuesKeys } from './keys';
import { fetchData } from '@/lib/fetch';

import type { NormalizedComment } from '../../comments/types';
import type { FetchError, UserId } from '@/types/api';
import type { Comment } from '../types';

const endpoint = (issueId: number, userId: UserId, noteId?: number | null) => (
  `mercury/issues/comments/${issueId}/${userId}${noteId ? `/${noteId}` : ''}`
);

const normalizeComment = (comment: Comment): NormalizedComment<Comment> => ({
  ...comment,
  id: comment.id,
  comment: comment.note,
  date: comment.last_updated,
  user: comment.username,
  children: comment.children.map(normalizeComment),
});

export const useGetComments = (
  issueId: number,
  userId: UserId,
) => (
  useQuery<Array<Comment>, FetchError, Array<NormalizedComment<Comment>>>({
    queryKey: issuesKeys.comments(issueId),
    queryFn: () => fetchData({
      endpoint: endpoint(issueId, userId),
      error: 'No Comment available',
    }),
    staleTime: 0,
    select: (comments) => comments.map(normalizeComment),
  })
);
