import { useMercuryTable } from '@/features/tablewrapper/hooks/useMercuryTable';

import AlertActions from './AlertActions';
import { IncidentStatuses } from '../types';
import { METADATA_TYPE } from '../../metadata/services/metadata';

interface TestProps {
  issueId: number;
  status: IncidentStatuses
  asButton?: boolean;
  disabled?: boolean;
}

const Test = ({
  issueId,
  status,
  asButton,
  disabled,
}: TestProps) => {
  const { query: { data, isLoading, isFetching } } = useMercuryTable();
  const { extra: extraData = {} } = data ?? {};
  const isAcknowledged = !!(extraData[issueId] && extraData[issueId][METADATA_TYPE.ACKNOWLEDGED]);
  const isResolved = !!(extraData[issueId] && extraData[issueId][METADATA_TYPE.RESOLVED]);

  return (
    <AlertActions
      issueId={issueId}
      isAcknowledged={isAcknowledged}
      isResolved={isResolved}
      asButton={asButton}
      loading={asButton && (isLoading || isFetching)}
      disabled={disabled}
      status={status}
    />
  );
};

export default Test;
