import Linkv2 from '@/components/content/link/Linkv2';
import { Group, Text } from '@mantine/core';

interface WelcomeMessageProps {
  username?: string;
  showBackButton: boolean;
  isLoginReady: boolean;
  onClick: VoidFunction;
}

const WelcomeMessage = ({
  username,
  showBackButton,
  isLoginReady,
  onClick,
}: WelcomeMessageProps) => (
  <>
    {isLoginReady && (
      <Group component={Text} justify="center" gap={5} mt={15}>
        Welcome, <b>{username}</b>

        {isLoginReady && (showBackButton) && (
          <Linkv2 as="button" onClick={onClick} type="button">Back</Linkv2>
        )}
      </Group>
    )}
  </>
);

export default WelcomeMessage;
