import type { PlainObject } from './general';

interface Options {
  method?: string;
  headers?: HeadersInit;
  body?: any;
}

export interface ContextVariables {
  source?: string;
}

export interface FetchDataArguments {
  endpoint: string;
  options?: Options;
  unauthorized?: string;
  error?: string;
  noContent?: string;
  requestLimit?: string;
  useResponseError?: boolean;
  resolveOnNoContent?: boolean;
  context?: ContextVariables;

}

export const ERROR_CLASSES = {
  QUERY_ATTRIBUTE_ERROR: 'QueryAttributeError',
} as const;

type ErrorClasses = typeof ERROR_CLASSES[keyof typeof ERROR_CLASSES];

export interface FetchError {
  code: number;
  details: PlainObject<any> & { class?: ErrorClasses };
  message: string;
}

export type PostPutMethod = 'POST' | 'PUT' | 'PATCH';

export type ClientId = string;

export type UserId = number;
