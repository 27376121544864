import {
  useState, useCallback, ChangeEvent, Dispatch, SetStateAction,
} from 'react';
import { Group, TextInput } from '@mantine/core';
import { Editor } from '@tiptap/react';

import Button from '../../button/Button';

const LinkWindow = ({
  editor,
  setLinkBoxOpen,
}: { editor: Editor | null, setLinkBoxOpen: Dispatch<SetStateAction<boolean>> }) => {
  const [url, setUrl] = useState('');

  const linkHandler = useCallback(() => {
    // cancelled
    if (url === null) { return; }

    // empty
    if (url === '') {
      editor?.chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();
      return;
    }

    // update link
    editor?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();

    setLinkBoxOpen(false);
  }, [url, editor]);

  return (
    <>
      <TextInput
        type="text"
        name="href"
        value={url}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setUrl(event.target.value)}
        placeholder="URL"
        autoFocus
      />

      <Group gap={5} mt={15} justify="space-between">
        <Button type="button" onClick={linkHandler} size="compact-xs">Insert</Button>
        <Button type="button" variant="outline" onClick={() => setLinkBoxOpen(false)} size="compact-xs">
          Cancel
        </Button>
      </Group>
    </>
  );
};

export default LinkWindow;
