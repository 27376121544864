import { useEffect } from 'react';
import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import { useToast } from '@/hooks/useToast';
import { fetchData } from '@/lib/fetch';

export interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      /**
       * Add an image
       */
      setImage: (options: {
        src?: string;
        loading?: boolean;
        error?: boolean;
      }) => ReturnType;
      removeImage: () => ReturnType;
    };
  }
}

type ImageComponentProps = {
  node: {
    attrs: {
      src: string;
      loading?: boolean;
      error?: boolean;
    };
  };
};

const upload = (data: FormData) => fetchData({
  endpoint: '/iris/attachment/images/upload',
  options: {
    method: 'POST',
    headers: { contentType: 'multipart/form-data; boundary=----image' },
    body: data,
  },
});

const ImageComponent = (props: ImageComponentProps) => {
  const { node } = props;
  const { createToast } = useToast();

  useEffect(() => {
    if (node.attrs.error) {
      createToast('Error uploading image', 'error');
    }
  }, [node.attrs.error]);

  if (node.attrs.loading) {
    return (
      <NodeViewWrapper>
        <div>Uploading...</div>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper>
      <img src={node.attrs.src} alt="" />
    </NodeViewWrapper>
  );
};

export const Image = Node.create<ImageOptions>({
  name: 'image',

  addOptions() {
    return {
      inline: false,
      allowBase64: true,
      HTMLAttributes: {},
    };
  },

  inline: false,
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      loading: {
        default: false,
      },
      error: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent);
  },

  addCommands() {
    return {
      setImage:
        (options) => ({ commands }) => commands.insertContent({
          type: this.name,
          attrs: options,
        }),
      removeImage: () => ({ commands }) => commands.deleteSelection(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('imagePaste'),
        props: {
          handleDOMEvents: {
            paste: (view, event) => {
              const items = (event.clipboardData)?.items;
              if (!items) {
                return false;
              }

              for (let i = 0; i < items.length; i += 1) {
                if (items[i].type.indexOf('image') !== -1) {
                  const file = items[i].getAsFile();

                  if (file) {
                    event.preventDefault();
                    const formData = new FormData();

                    formData.append('file', file);

                    const setImage = async () => {
                      try {
                        const d = await upload(formData);

                        this.editor
                          .chain()
                          .setImage({ src: `/api/iris/attachment/images/${d[0]}`, loading: false })
                          .run();
                      } catch (error) {
                        this.editor.chain().setImage({ error: true }).run();
                        this.editor.chain().removeImage().run();
                      }
                    };

                    setImage();
                  }
                }
              }

              return false;
            },
          },
        },
      }),
      new Plugin({
        key: new PluginKey('imageDrop'),
        props: {
          handleDOMEvents: {
            drop: (view, event) => {
              // const { createToast } = useToast();

              if (event?.dataTransfer?.files) {
                const { files } = event.dataTransfer;
                const file = files.item(0);

                if (file && file.type.includes('image')) {
                  event.preventDefault();

                  this.editor.chain().setImage({ loading: true }).run();

                  const formData = new FormData();

                  formData.append('file', file);

                  const setImage = async () => {
                    try {
                      const d = await upload(formData);

                      this.editor
                        .chain()
                        .setImage({ src: `/api/iris/attachment/images/${d[0]}`, loading: false })
                        .run();
                    } catch (error) {
                      this.editor.chain().setImage({ error: true }).run();
                      this.editor.chain().removeImage().run();
                    }
                  };

                  setImage();
                }
              }

              return false;
            },
          },
        },
      }),
    ];
  },
});
