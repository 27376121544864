import type { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, string } from 'superstruct';

import Form from '@/components/content/form/Form';
import EditorComp from '@/components/content/rte/Editor';

import type { ExtraButton, OnSubmitFunc } from '@/types/forms';
import type { CommentBoxField } from '../types';

// Misc
import { required } from '@/utils/inputValidation';

interface CommentBoxProps {
  onSubmit: OnSubmitFunc<CommentBoxField>;
  submitButton: { text: string; disabled: boolean; };
  comment?: string;
  name?: string;
  extraButton?: ExtraButton<CommentBoxField>;
  commentOptions?: ReactNode;
  minimalToolbar?: boolean;
}

const CommentBox = ({
  onSubmit,
  name = 'client-comment',
  comment = '',
  submitButton,
  extraButton,
  commentOptions,
  minimalToolbar,
}: CommentBoxProps) => {
  const schema = ST({
    comment: required(string(), 'Comment is required'),
  });

  return (
    <Form<CommentBoxField>
      name={name}
      options={{ resolver: superstructResolver(schema), defaultValues: { comment } }}
      onSubmit={onSubmit}
      submitButton={submitButton}
      extraButton={extraButton}
      fields={{ comment: 'Comment' }}
    >
      {({ control, formState: { errors } }) => (
        <Controller
          name="comment"
          control={control}
          render={({ field: { value, onChange } }) => (
            <EditorComp
              content={value}
              onChange={onChange}
              error={errors?.comment}
              extraOptions={commentOptions}
              minimalToolbar={minimalToolbar}
            />
          )}
        />
      )}
    </Form>
  );
};

export default CommentBox;
