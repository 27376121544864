import { ScrollArea, Stack } from '@mantine/core';

// Components
import DownLoadLink from '@/features/downloadlink/DownLoadLink';

// Hooks
import { useAuth } from '@/hooks/useAuth';

// Types
import type { Attachment } from '../types';

// Misc
import { plural } from '@/utils/string';

import classes from './Attachments.module.css';

/**
 * Removes all the images contained in the description from the attachment list.
 * Redmine includes everything as an attachment but this images serve no purpose.
 *
 * REGEX Explanation
  \!\[ look for "!["
  [^ \] ]* then any amount of chars except "]"
  \]\( then "]("
  ( then capture a group (that's the m[1])
  [^ \s\) ]* that contains any amount of chars except spaces or ")"
  ) end group
  /gm
 */
export const getAttachments = ({
  text,
  attachments = [],
}: { text: Array<string>; attachments: Array<Attachment> }) => {
  const joinedText = text.join(' ');
  let attachmentBlackList: Array<string> = [];

  try {
    attachmentBlackList = (
      joinedText
        ? Array.from(joinedText.matchAll(/!\[[^\]]*\]\(([^\s)]*)/gm))
          .filter(Boolean)
          .map((m) => (decodeURIComponent(m[1].toLowerCase())))
        : []
    );
  } catch (e) {
    // Ignore
  }

  const renderAttachments = attachments.filter((val) => !attachmentBlackList.includes(val.filename.toLowerCase()));

  return renderAttachments.length ? renderAttachments : null;
};

interface AttachmentProps {
  attachments: Array<Attachment> | null;
  issue: string | number;
}

const Attachments = ({ attachments, issue }: AttachmentProps) => {
  const { userInfo } = useAuth();

  if (!attachments) return null;

  const nOfDocuments = attachments.length;
  const from = `attachment/${userInfo.user_id}/${issue}`;

  return (
    <div>
      <ScrollArea h={80}>
        <Stack gap={15} className={classes.list} component="ul">
          {attachments.map((att) => (
            <li key={att.id}>
              <DownLoadLink attachment={att} from={from} />
            </li>
          ))}
        </Stack>
      </ScrollArea>

      {nOfDocuments > 0 && (
        <span className={classes.number}>
          {nOfDocuments} {plural(nOfDocuments, 'document', 'documents')}
        </span>
      )}
    </div>
  );
};

export default Attachments;
