import { Stack, Text } from '@mantine/core';

import PillsList from '@/components/content/pills/PillsList';
import RiskIndicators from '../../../../riskindicators/RiskIndicators';
import Markdown from '@/components/content/markdown/Markdown';
import List from '@/components/content/list/List';
import RedmineFieldList from '../../../components/RedmineFieldList';
import Priority from '@/components/content/priority/Priority';

import { createHelper } from '@/client/features/issue/utils/helper';
import { getContextData } from '../../../utils/context';
import { formatIsoDate } from '@/utils/toDate';
import { getTaCapabilityAsNumber } from '@/utils/getTaCapabilityAsNumber';

import type { MetisThreatActor } from '../types';
import { aggregateFromMetisRelationship,
  aggregateFromMetisRelationshipWithAmount } from '../../../utils/aggregateFromMetisRelationship';
import { getValues } from '@/utils';

const helper = createHelper<MetisThreatActor>();

export const threatActorDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Threat Actor</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'locations')),
        technologies: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'technologies')),
        industry_sectors: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'industries')),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Threat Information',
    submodules: [
      helper.accessor((data) => aggregateFromMetisRelationship(data.raw_intel, 'threat_actor_capability'), {
        title: 'Capability',
        element: ({ getValue, id }) => (
          <Priority
            priority={getTaCapabilityAsNumber(getValues(getValue()))}
            id={String(id)}
            w="fit-content"
            mt={5}
          />
        ),
      }),
      helper.accessor((data) => aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'threat_actor_intent'), {
        title: 'Intent',
        element: ({ getValue }) => <RedmineFieldList items={getValues(getValue())} />,
      }),
      helper.accessor((data) => aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'threat_actor_motivation'), {
        title: 'Motivation',
        element: ({ getValue }) => <RedmineFieldList items={getValues(getValue())} />,
      }),
      helper.accessor((data) => data.locations, {
        title: 'Sponsoring Country',
        element: ({ getValue }) => getValues(getValue())[0],
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Last Updated',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => aggregateFromMetisRelationship(data.raw_intel, 'technologies'), {
        title: 'Technologies Affected',
        element: ({ getValue }) => (
          <PillsList
            max={5}
            pills={getValues(getValue())}
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data), {
        title: 'Context',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
        scroll: false,
      }),
    ],
  },
];
