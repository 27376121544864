import { Stack, Text } from '@mantine/core';

import PillsList from '@/components/content/pills/PillsList';
import RiskIndicators from '../../../../riskindicators/RiskIndicators';
import Markdown from '@/components/content/markdown/Markdown';
import List from '@/components/content/list/List';

import { createHelper } from '@/client/features/issue/utils/helper';
import { getContextData } from '../../../utils/context';
import { formatIsoDate } from '@/utils/toDate';
import { aggregateFromMetisRelationship,
  aggregateFromMetisRelationshipWithAmount } from '../../../utils/aggregateFromMetisRelationship';
import { getValues } from '@/utils';

import type { MetisMalwareKit } from '../types';

const helper = createHelper<MetisMalwareKit>();

export const malwareKitsDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Malware Kit</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'locations')),
        technologies: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'technologies')),
        industry_sectors: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'industries')),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Malware Information',
    submodules: [
      helper.accessor((data) => data.type, {
        title: 'Type',
      }),
      helper.accessor((data) => data.stage, {
        title: 'Attack Stage',
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Last Updated',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => aggregateFromMetisRelationship(data.raw_intel, 'technologies'), {
        title: 'Technology Affected',
        element: ({ getValue }) => (
          <PillsList
            max={5}
            pills={getValues(getValue())}
          />
        ),
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data), {
        title: 'Context',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
        scroll: false,
      }),
    ],
  },
];
