import {
  type PropsWithChildren, createContext, useState, useContext, SetStateAction, Dispatch,
  useMemo,
} from 'react';

import { ACCESS_MODULES } from '@/constants/permissions';
import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';

interface EditContextProps {
  isEditing: boolean;
  allowedEdit: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

export const EditContext = createContext<EditContextProps | null>(null);

export const EditContextProvider = ({ children }: PropsWithChildren) => {
  const [isEditing, setIsEditing] = useState(false);
  const { hasAccess } = useMercuryPermissions();
  const allowedEdit = hasAccess({ modules: [ACCESS_MODULES.MODULE_ALERTS_MGMT] });

  const value = useMemo(() => ({ isEditing, setIsEditing, allowedEdit }), [isEditing, setIsEditing]);

  return (
    <EditContext.Provider value={value}>
      {children}
    </EditContext.Provider>
  );
};

export const useEdit = () => {
  const editContext = useContext(EditContext);

  if (!editContext) {
    throw new Error('EditContext is not initialized. This hook must be used inside a EditContextProvider');
  }

  return editContext;
};
