/* eslint-disable no-useless-escape */
import { Text, TextProps } from '@mantine/core';
import styled, { css } from 'styled-components';
import ReactMarkdown, { UrlTransform } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeRaw from 'rehype-raw';

import Link from './components/Link';
import MarkdownImage from './components/Image';
import MercuryTitle from '../title/Title';
import Table from './components/Table';

export interface MarkdownProps {
  text: string;
  transformImageUri?: UrlTransform;
  footnotesClass?: string;
  disableLinks?: boolean;
  disabledLinkTooltip?: string;
  disallowedElements?: Array<string>;
  textProps?: TextProps;
  renderExternalLinks?: boolean;
}

export const richTextCss = css`
  p {
    font-size: 16px;
    line-height: 22px;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
  }

  pre {
    background-color: var(--code-block-bg);
    border-left: 3px solid var(--quo-power);
    page-break-inside: avoid;
    font-family: Courier, monospace;
    font-size: 14px;
    line-height: 1.6;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    white-space: pre-wrap;

    code {
      background-color: transparent;
      font-size: inherit;
      color: inherit;
      word-break: normal;
      padding: 0;
    }
  }

  code {
    font-family: Courier, monospace;
    padding: 0.1rem 0.4rem;
    background-color: var(--code-block-bg);
    border-radius: 0.1335rem;

    + code {
      margin-left: 5px;
    }
  }

  blockquote {
    background-color: var(--code-block-bg);
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 15px;
    margin-left: 15px;

    li {
      margin-top: 5px;
    }
  }

  sup {
    a {
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    }
  }
`;

export const RichText = styled.div<{ $lineClamp?: number }>`
  ${({ $lineClamp }) => $lineClamp && $lineClamp > 0
    && `
        @supports (-webkit-hyphens:none) {
          * {
            display: inline;

            &::after {
              content: " \\A\\A";
              white-space: pre;
            }
          }
        }
      `
}

  &:not(:first-child) {
    margin-top: 10px;
  }

  h1 + & {
    margin-top: 25px;
  }

  > * + * {
    margin-top: 15px;
  }

  > h1 + *,
  > h2 + *,
  > h3 + * {
    margin-top: 7.5px;
  }

  > ul > li:first-child {
    margin-top: 0;
  }

  ${richTextCss}

  li & {
    margin-top: 0;
  }

  .footnotes {
    font-size: smaller;
    color: var(--quo-grey-75);
    border-top: 1px solid var(--quo-grey-15);

    li {
      scroll-margin-top: 75px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: anywhere;

    th {
      width: 200px;
      text-align: left;
    }

    tr {
      vertical-align: middle;

      &:first-child {
        td:first-child {
          border-left-style: solid;
          border-top-left-radius: 4px;
        }

        td:last-child {
          border-right-style: solid;
          border-top-right-radius: 4px;
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid var(--default-border);

          &:first-child {
            border-left-style: solid;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 4px;
          }
        }
      }



      thead & {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
      }

      th {
        color: var(--table-th-color);
        text-transform: capitalize;
      }

      td {
        border-top: 1px var(--quo-grey-25);
        border-style: solid none;
        border-bottom: 1px;

        &:first-child {
          border-left: 1px solid var(--default-border)
        }

        &:last-child {
          border-right: 1px solid var(--default-border)
        }

        tbody & {
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }

      th,
      td {
        padding: 10px;

        @media (min-width: ${(props) => props.theme.breakpoints.M}) {
          padding: 12px;
        }
      }

      th,
      td {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  /* Hide the section label for visual users. */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
  }

  [data-footnote-ref]::before {
    content: '[';
  }

  [data-footnote-ref]::after {
    content: ']';
  }
`;

const Markdown = ({
  text,
  transformImageUri,
  footnotesClass = '',
  disableLinks,
  disabledLinkTooltip,
  disallowedElements,
  textProps = {},
  renderExternalLinks,
}: MarkdownProps) => {
  const getFootnoteClass = (footnote: string) => footnote.replace(/[^a-zA-Z0-9]/g, '');

  return (
    <Text component="div" {...textProps}>
      <RichText $lineClamp={textProps.lineClamp ?? 0}>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[[rehypeExternalLinks, { target: '_blank', rel: ['nofollow'] }], remarkGfm, remarkBreaks]}
          disallowedElements={disallowedElements}
          /* eslint-disable */
          components={{
            h1: ({ children }) => <MercuryTitle order={1}>{children}</MercuryTitle>,
            h2: ({ children }) => <MercuryTitle order={2}>{children}</MercuryTitle>,
            h3: ({ children }) => <MercuryTitle order={3}>{children}</MercuryTitle>,
            img: MarkdownImage,
            li: ({ node, className, children, ...props }) => {
              const isFootnote = props.id?.includes('user-content');
              let footnoteSelector = props.id;

              if (isFootnote) {
                footnoteSelector = `${getFootnoteClass(footnotesClass)}-${props.id} `;
              }

              return (
                <li {...props} id={footnoteSelector}>
                  {children}
                </li>
              );
            },
            a: (props) => {
              return (
                <Link
                  {...props}
                  footnotesClass={getFootnoteClass(footnotesClass)}
                  disableLinks={disableLinks}
                  disabledLinkTooltip={disabledLinkTooltip}
                  renderExternalLinks={renderExternalLinks}
                />
              );
            },
            table: Table
            /* eslint-enable */
          }}
          urlTransform={transformImageUri}
        >
          {text}
        </ReactMarkdown>
      </RichText>
    </Text>
  );
};

export default Markdown;
