import { Group, Text } from '@mantine/core';

import BaseResult from '@/client/features/search/components/BaseResult';
import List from '@/components/content/list/List';
import IssueIDLinkv2 from '@/client/features/issueIdLink/IssueIdLink';
import Tooltip from '@/components/content/tooltip/Tooltip';
import IssueWrapperV2 from '@/client/features/issue/components/IssueWrapper';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import { formatIsoDate } from '@/utils/toDate';
import { aggregateFromMetisRelationship } from '../../../utils/aggregateFromMetisRelationship';
import { getContextData } from '../../../utils/context';
import { capitalize } from '@/utils/string';
import { vulnerabilitiesDrawer } from '../pages/vulnerabilities.drawer';

import type { MetisVulnerability } from '../types';
import type { Includes } from '@/types/mercury-data-types/metis';
import type { MetisIncident } from '@/client/features/incidentmanagement/types';

const VulnerabilitiesSearchResult = ({
  data,
}: { data: Includes<MetisVulnerability, { incidents: Array<MetisIncident> }> }) => {
  const ta = aggregateFromMetisRelationship(data.raw_intel, 'threat_actors');
  const vulnerabilities = aggregateFromMetisRelationship(data.raw_intel, 'vulnerabilities');
  const malwareKits = aggregateFromMetisRelationship(data.raw_intel, 'malware_kits');
  const context = getContextData({ vulnerabilities, malware_kits: malwareKits, threat_actors: ta });

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'critical':
        return 'var(--quo-pink)';
      case 'high':
        return 'var(--quo-power)';
      case 'medium':
        return 'var(--quo-yellow)';
      case 'low':
        return 'var(--quo-green)';
      default:
        return 'var(--quo-noir-50)';
    }
  };

  return (
    <BaseResult
      title={{ id: data.id, name: data.name }}
      content={data.description}
      tracker={mercuryTrackers[MercuryTrackersIds.vulnerabilitiesCat]}
      drawerContent={(
        <IssueWrapperV2
          top={vulnerabilitiesDrawer}
          data={data}
          id={data.id}
          removePadding
        />
      )}
      footer={[
        <Text size="sm">
          <Text fw="bold" span>Last Updated:</Text> {formatIsoDate(data.updated_at)}
        </Text>,
        data.incidents.length > 0
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Related Incidents:</Text>
            <List
              gap={10}
              direction="row"
              items={data.incidents}
              renderItem={({ id }) => (
                <IssueIDLinkv2
                  value={id}
                  name={String(id)}
                  tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
                  useParent
                  usePage
                />
              )}
            />
          </Group>
        ),
        <Text size="sm">
          <Text fw="bold" span>Priority: </Text>
          <Text fw="bold" c={getPriorityColor(data.priority)} span>{capitalize(data.priority)}</Text>
        </Text>,
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip title={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default VulnerabilitiesSearchResult;
