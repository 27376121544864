import { OIncidentsStatus, OTakedownStatus } from '../types';

export const incidentsStatusColorCoding = {
  [OIncidentsStatus.New]: { bg: 'var(--quo-noir-75)' },
  [OIncidentsStatus.Pending]: { bg: 'var(--quo-grey-75)' },
  [OIncidentsStatus.Acknowledged]: { bg: 'var(--quo-green)' },
  [OIncidentsStatus.Resolved]: { bg: 'var(--quo-blue-75)' },
};

export const takedownsStatusColorCoding = {
  [OTakedownStatus.Approved]: { bg: 'var(--quo-grey-75)', tooltip: '' },
  [OTakedownStatus.New]: { bg: 'var(--quo-grey-75)', tooltip: '' },
  [OTakedownStatus.Pending]: { bg: 'var(--quo-grey-75)', tooltip: 'Waiting for Provider' },
  [OTakedownStatus.Feedback]: { bg: 'var(--quo-yellow)', color: 'var(--quo-char)', tooltip: 'Successfully Closed' },
  [OTakedownStatus.Resolved]: { bg: 'var(--quo-green)', tooltip: 'Successfully Closed' },
  [OTakedownStatus.Rejected]: { bg: 'var(--quo-power-50)' },
  [OTakedownStatus.Closed]: { bg: 'var(--quo-grey-75)', tooltip: 'Unsuccessfully Closed' },
  [OTakedownStatus.InProgress]: { bg: 'var(--quo-grey-75)' },
};
