import type { DateValueType } from '@/components/content/chart/types';
import type { NormalizedRedmineField } from '../../catalogs/types';
import { MetisCatalogs, MetisIssueCommonFields, MetisRelationships } from '@/types/mercury-data-types/metis';

type CategoryGfx = {
  instance_id: number;
  value: string;
  amount: number;
};

type AckedByGfx = Record<string, number>;

export interface IncidentsGFX {
  incident_by_category_plot: Array<DateValueType>;
  incident_by_category: Array<CategoryGfx>;
  incident_acted_by_analyst: AckedByGfx;
}

export const OIncidentsStatus = {
  New: 'New',
  Pending: 'Pending',
  Acknowledged: 'Acknowledged',
  Resolved: 'Resolved',
} as const;

export type IncidentStatuses = typeof OIncidentsStatus[keyof typeof OIncidentsStatus];

export interface IncidentsTableAlertRaw {
  client_id: number;
  issue_id: number;
  priority_id: number;
  priority_name: string;
  source_dtg: string;
  subject: string;
  name_sources: string;
  drp_category: string;
  drp_subcategory: string;
  takedowns: Array<number> | null;
  created_on: string;
  updated_on: string;
  assignee: string;
  status: IncidentStatuses;
  client_name: string;
}

export interface IncidentsTableAlert extends IncidentsTableAlertRaw {
  source: NormalizedRedmineField;
}

export interface MetisIncident extends MetisIssueCommonFields, MetisCatalogs, MetisRelationships {
  status: IncidentStatuses;
  category: string;
  subcategory: string;
  iocs: Array<string>;
  takedowns: Record<number, string>;
  acknowledged_by: string;
}
