/* eslint-disable no-useless-escape */
export function capitalize(str: string) {
  if (!str) return '';

  if (str.length === 0) {
    return str;
  }

  return `${str[0].toLocaleUpperCase()}${str.substring(1)}`;
}

export function removeSpecialCharsAndSpaces(str: string) {
  return str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '');
}

export function plural(count: number, onOne: string, onMany: string) {
  return count === 1 ? onOne : onMany;
}
