import { METADATA_TYPE } from '../../metadata/services/metadata';
import { IncidentStatuses } from '../types';
import AlertActions from './AlertActions';
import { ExtraDataObj } from '@/types/mercury-data-types';

interface TestProps {
  issueId: number;
  extra: ExtraDataObj;
  loading: boolean;
  status: IncidentStatuses
}

const Test = ({
  issueId,
  extra,
  loading,
  status,
}: TestProps) => {
  const isAcknowledged = !!(extra[issueId] && extra[issueId][METADATA_TYPE.ACKNOWLEDGED]);
  const isResolved = !!(extra[issueId] && extra[issueId][METADATA_TYPE.RESOLVED]);

  return (
    <AlertActions
      issueId={issueId}
      isAcknowledged={isAcknowledged}
      isResolved={isResolved}
      loading={loading}
      status={status}
      asButton
    />
  );
};

export default Test;
