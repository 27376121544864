import type { Submodule, Accessor } from '../types/modulesv2';

export function createHelper<T>() {
  return {
    accessor<R>(
      accessor: Accessor<T, R>,
      module?: Submodule<R>,
    ) {
      return {
        ...module,
        accessor,
      };
    },
  };
}
