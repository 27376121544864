import { useCallback } from 'react';
import { Transition } from '@mantine/core';

// Components
import Tabs from '@/components/content/tabs/Tabs';
import DropdownMenu from '@/components/layout/nav/topbar/DropdownMenu';
import NotificationsBadgeIcon from './components/BadgeIcon';
import ReadAll from './components/ReadAll';
import Notification from './components/Notification';

// Styles & Assets
import classes from './Notifications.module.css';
import { useNotifications } from './components/useNotifications';

const NotificationsMenu = () => {
  const {
    unreadItems,
    unacknowledgedItem,
    isLoading,
    refetch,
    totalUnread,
    totalUnacknowledged,
    totalNotifications,
    getItem,
    getUnread,
    getUnacknowledged,
  } = useNotifications();

  const Badge = useCallback(() => (
    <NotificationsBadgeIcon totalNotifications={totalNotifications} />
  ), [totalNotifications]);

  const tabs = [
    {
      tab: `Unread (${totalUnread})`,
      val: 'unread',
    },
    {
      tab: `Unacknowledged (${totalUnacknowledged})`,
      val: 'unacknowledged',
    },
  ];

  return (
    <Transition
      mounted={totalNotifications > 0}
      transition="slide-left"
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <div style={styles}>
          <DropdownMenu icon={Badge} size={335} hideArrow={isLoading}>
            <div className={classes.wrapper}>
              <h1 className={classes.title}>Notifications</h1>

              <Tabs
                tabs={tabs}
                id="notifications-menu"
                defaultValue="unacknowledged"
                small
                justify="space-between"
                mt={25}
                grow
              >
                <Notification
                  items={unreadItems}
                  getNotification={getUnread}
                  getItem={getItem}
                  total={totalUnread}
                  emptyMsg="unread"
                  footer={totalUnread > 0 ? <ReadAll refetch={refetch} /> : null}
                />
                <Notification
                  items={unacknowledgedItem}
                  getNotification={getUnacknowledged}
                  getItem={getItem}
                  emptyMsg="unacknowledged"
                  total={totalUnacknowledged}
                />
              </Tabs>
            </div>
          </DropdownMenu>
        </div>
      )}
    </Transition>
  );
};

export default NotificationsMenu;
