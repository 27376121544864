/* eslint-disable */

import { CATALOGS } from "../permissions";

export const NEWS = [
  {
    title: 'Evolving our Incident Management Panel and more',
    date: '22/08/2024',
    content: `
      <p>
        Go further within the <b>Incident Management Panel</b> thanks to a new Resolved status and the capability to edit incidents’ descriptions for those subscribed to the ALERTS MANAGEMENT module.
      </p>
      <p>
        Get more of our <b>Global Search Bar</b> with the recently added contextual information such as related incidents, threat actors, malware kits, industry sectors, etc.
      </p>
    `,
  },
  {
    title: 'Mercury Evolves with the Analytics Panel',
    date: '27/05/2024',
    content: `
      <p>
      Introducing the new <b>Analytics Panel</b> in Mercury, designed exclusively for our Professional subscribers to enable them to dig deep down within our Data Lake to discover whether they might be emerging threats affecting them or their context.
Based on your feedback, we have completed the first version of our <b>API for managing alerts, incidents, and takedowns end-to-end</b>. You can now not only retrieve alerts, incidents, or takedowns but also flag them as false positives, create new incidents, and acknowledge them appropriately.
      </p>
    `,
  },
  {
    title: 'Introducing Identity Panel, Alerts Management, and more',
    date: '24/04/2024',
    content: `
      <p>
      Gain comprehensive insights into credential leaks affecting your employees and customers with our new
      <b>Identity Panel</b>. Easily explore detailed tables and drill-down capabilities to stay ahead of potential vulnerabilities.
      </p>
      <p>
      Enjoy enhanced visibility and control over alerts collected for your domains and keywords. Subscribers to the <b>Alerts Management Module</b> can take direct action from the Incident Management Panel, marking alerts as false positives or creating incidents based on our QuoAI suggestions.
      </p>
      <p>
      Keep track of takedown requests effortlessly with the <b>added requestor information</b> in the ticket. Ensure transparency and accountability throughout the takedown process.
      </p>
      <p>
      Explore our Knowledge Base without limitations, thanks to <b>resolved signals issues</b> within the global search bar. Enjoy seamless navigation and access to the information you need, when you need it.
      </p>
    `,
  },
];

export const USER_INVITE = (user: string) =>
  `<p>A new token was generated for the user <b>${user}</b>. The user will be able to access the portal after choosing a password</p>`;

export const REGISTER_DISCLAIMER = `
<p>
  By clicking <b>Register</b>, you agree to our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Terms</a>.
  Learn how we collect, use and share your data in our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Data Policy</a> and how we use cookies and similar
  technology in our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Cookie Policy</a>.
</p>`;

export const PASSWORD_INFO = `Please enter a password with at least 12 letters including a digit, mixed case letters and a special character (!#$%&'()*+,./:;?@[\]^_{|})`;
export const PASSWORD_RECOVERY_SENT =
  '<p>An email has been sent to the email address provided, please check your inbox for a reset link. If you do not receive this email in the next 15 minutes, please contact us at <a href="mailto:support@quointelligence.eu">support@quointelligence.eu</a> for further assistance.</p>';

export const INVALID_FORCED_2FA =
  'Your organisation has requested you to activate 2-factor authentication but we seem to be having some issues creating a valid token for you. Please try again later.\n\n If the issue persists please contact customer support.';

export const FINISHED_SUBTITLE = 'Finished Intelligence products by QuoIntelligence Analysis team.';
export const RAW_SUBTITLE =
  'Raw Intelligence coming from OSINT/CYBINT/HUMINT sources analysed and classified by QuoIntelligence analysis.';

export const BLOCKED_TABLE_MESSAGE = 'A premium subscription is needed';

export const SERVER_ERROR = 'We seem to be having an issue with this request, please try again later.';

export const DASHBOARD_UPSELL = 'These dashboards enable you to understand your most relevant threats in depth.';
export const ISSUES_UPSELL =
  'These reports are packed with valuable information that enables you to understand the latest threats in depth.';

export const SIGNALS_BETA_MESSAGE = "Signals are unvetted information we collect and provide to our analysts so they can verify it and build tailored intelligence for you. From now on you will also have access to this raw data. Be aware that you might find quite unstructured pieces of information.";
export const IDENTITY_BETA_MESSAGE = "For the time being this panel is exclusively based on botnet log files. You can find same old credential leak alerts in the Incident Management panel";
