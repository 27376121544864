import { Group, Text } from '@mantine/core';

import BaseResult from './BaseResult';
import CatalogLinkList from '../../cataloglink/CatalogLinkList';
import IconMap from '../../iconmap/IconMap';
import List from '@/components/content/list/List';
import Tooltip from '@/components/content/tooltip/Tooltip';
import LocationIcon from '../../iconmap/LocationIcon';
import Pill from '@/components/content/pills/Pill';

import { toIsoDate } from '@/utils/toDate';
import { findInObject } from '@/utils/findInObject';
import { SIGNALS_PATHS } from '@/constants/opensearchpaths';
import { getContextData } from '../../catalogs/utils/context';
import { qiqlAttributesIntoIdNameArray } from '@/utils/qiqlQueryAdapter';
import { sourcesDrawer } from '../../catalogs/features/sources/pages/sources.drawer';
import { objectHasValues } from '@/utils';
import { CATEGORY_ICONS } from '../../iconmap/icons/intel_category';
import { INDUSTRY_ICON_MAP } from '../../iconmap/icons/industry';
import IssueWrapperV2 from '../../issue/components/IssueWrapper';
import { signalDrawer } from '../../analyticsdashboard/components/signals.drawer';

const SignalSearchResult = ({ data: signal }: { data: any }) => {
  const id = signal.signal_id;
  const title = findInObject(signal, SIGNALS_PATHS.TITLE);
  const text = findInObject(signal, SIGNALS_PATHS.TEXT);
  const timestamp = findInObject(signal, SIGNALS_PATHS.TIMESTAMP);
  const sources = qiqlAttributesIntoIdNameArray(signal, SIGNALS_PATHS.CATALOG.SOURCE);
  const category = findInObject(signal, SIGNALS_PATHS.CATALOG.CATEGORY, [], (c) => c[0]);
  const context = getContextData(
    findInObject(signal, SIGNALS_PATHS.CATALOG.ALL),
    ['threat_actors', 'malware_kits', 'vulnerabilities'],
  );
  const locations = qiqlAttributesIntoIdNameArray(signal, SIGNALS_PATHS.CATALOG.LOCATIONS).reduce((accu, curr) => {
    accu[curr.id] = curr.name;

    return accu;
  }, {} as Record<number, string>);
  const industries = qiqlAttributesIntoIdNameArray(signal, SIGNALS_PATHS.CATALOG.INDUSTRY).reduce((accu, curr) => {
    accu[curr.id] = curr.name;

    return accu;
  }, {} as Record<number, string>);

  return (
    <BaseResult
      title={{ id: signal.signal_id, name: title }}
      content={text}
      drawerContent={(
        <IssueWrapperV2
          top={signalDrawer}
          data={signal}
          id={id}
          removePadding
        />
      )}
      footer={[
        timestamp
        && (
          <Text size="sm">
            <Text fw="bold" span>Date:</Text> {toIsoDate(timestamp)}
          </Text>
        ),
        sources.length > 0
        && (
          <Group gap={5}>
            <Text size="sm" fw="bold" span>Source:</Text>
            <CatalogLinkList
              value={sources}
              catalog="sources"
              content={sourcesDrawer}
            />
          </Group>
        ),
        category
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Category:</Text>
            <IconMap
              id={id}
              icons={CATEGORY_ICONS}
              map={{ [category]: category }}
              layout="small"
              renderLabelWithoutIcon={(label) => (
                <Pill
                  component="span"
                  bg="light-dark(var(--icon-column-stroke), var(--quo-blue)"
                  color="var(--quo-white)"
                  value={label}
                />
              )}
              showTooltip
            />
          </Group>
        ),
        objectHasValues(locations)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Locations:</Text>
            <LocationIcon locations={locations} id={id} max={2} layout="small" />
          </Group>
        ),
        objectHasValues(industries)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Industries:</Text>
            <IconMap
              id={id}
              map={industries}
              icons={INDUSTRY_ICON_MAP}
              max={3}
              layout="small"
              renderLabelWithoutIcon={(label) => label}
              showTooltip
            />
          </Group>
        ),
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip title={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default SignalSearchResult;
