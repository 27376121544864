import { Stack, Text } from '@mantine/core';
import Markdown from '@/components/content/markdown/Markdown';
import RiskIndicators from '../../../../riskindicators/RiskIndicators';

import { createHelper } from '@/client/features/issue/utils/helper';

import { getValues } from '@/utils';
import { aggregateFromMetisRelationshipWithAmount } from '../../../utils/aggregateFromMetisRelationship';

import type { MetisAttck } from '../types';

const helper = createHelper<MetisAttck>();

export const attckDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">ATT&CK</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'locations')),
        technologies: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'technologies')),
        industry_sectors: getValues(aggregateFromMetisRelationshipWithAmount(data.raw_intel, 'industries')),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.mitre_kill_chain, {
        title: 'MITRE tactic',
        scroll: false,
      }),
      helper.accessor((data) => data.url, {
        title: 'MITRE URL',
        scroll: false,
      }),
    ],
  },
];
