import List from '@/components/content/list/List';

import classes from './IocList.module.css';

interface IocListProps {
  iocs: string | Array<string>;
}

const IocList = ({ iocs }: IocListProps) => {
  const items = (typeof iocs === 'string' ? [iocs] : iocs).map((val: string) => val.split('\r\n')).flat();

  return <List items={items} renderItem={(item) => <span className={classes.item}>{item}</span>} />;
};

export default IocList;
