import CommentComp from '@/client/features/comments/components/Comment';
import { useComment } from '../../hooks/useComment';
import CommentOptions from './CommentOptions';

interface IssueCommentProps {
  id: number;
  parent?: number;
  issueId: number;
  comment: any;
  title: string;
}

const IssueComment = ({ id, parent, issueId, comment, title }: IssueCommentProps) => {
  const {
    addComment,
    editComment,
    removeComment,
    disableButtons,
    commentOptions,
    setCommentOption,
    isReplying,
    setIsReplying,
    isEditing,
    setIsEditing,
    allowEdit,
    allowRemove,
  } = useComment({ id, parent, issueId, title, owner: comment.user_id });

  return (
    <CommentComp
      comment={comment}
      onEdit={editComment}
      onRemove={removeComment}
      onReply={addComment}
      disableButtons={disableButtons}
      commentOptions={<CommentOptions options={commentOptions} setOption={setCommentOption} />}
      isReplying={isReplying}
      setIsReplying={setIsReplying}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      allowEdit={allowEdit}
      allowRemove={allowRemove}
      minimalToolbar
      allowReply
    />
  );
};

export default IssueComment;
