export type SupportFormFields = {
  subject: IntelligenceSubject | SupportSubject | string;
  otherSubject: string;
  description: string;
  due_date: string;
  attachment: any;
  to: SupportType;
  ticket_id: string;
  subclient_id: string;
};

export const SupportFormLabels = {
  subject: 'Subject',
  description: 'Description',
  otherSubject: 'Write a subject',
  dueDate: 'Due Date',
  attachment: 'Attached Files',
  subclient: 'Subclient',
} as const;

export const INTELLIGENCE_SUBJECTS = {
  AD_HOC: 'Request for Ad-Hoc intelligence',
  ADDITIONAL_INFO: 'Request for additional information',
  CALL_WITH_ANALYST: 'Request for a call with an analyst',
} as const;

export type IntelligenceSubject = typeof INTELLIGENCE_SUBJECTS[keyof typeof INTELLIGENCE_SUBJECTS];

export const SUPPORT_SUBJECTS = {
  COMPANY_INFO_UPDATE: 'Company information update',
  COMPANY_GOLDEN_LIST_UPDATE: 'Company golden list update',
  PREMIUM_TRIAL: 'Request for premium trial',
  ADD_CREDITS: 'Add more credits',
  CALL_WITH_SUPPORT: 'Request a call with Support',
  OTHER: 'Other',
} as const;

export type SupportSubject = typeof SUPPORT_SUBJECTS[keyof typeof SUPPORT_SUBJECTS];

export const SUPPORT_TYPES = {
  INTEL: 'intel',
  SUPPORT: 'support',
  TAKEDOWN: 'takedown',
} as const;

export type SupportType = typeof SUPPORT_TYPES[keyof typeof SUPPORT_TYPES];

export interface SupportResponse {
  issue: {
    id: number;
    description: string;
    subject: string;
  }
}
