import { Stack, Text } from '@mantine/core';
import RiskIndicators from '../../riskindicators/RiskIndicators';
import List from '@/components/content/list/List';

import { createHelper } from '../../issue/utils/helper';

import { findInObject } from '@/utils/findInObject';
import { SIGNALS_PATHS } from '@/constants/opensearchpaths';
import { formatIsoDate } from '@/utils/toDate';

import type { Module } from '../../issue/types/modulesv2';
import { getContextData } from '../../catalogs/utils/context';
import { CATEGORY_ICONS } from '../../iconmap/icons/intel_category';
import IconMap from '../../iconmap/IconMap';
import { qiqlAttributesIntoIdNameArray } from '@/utils/qiqlQueryAdapter';

const helper = createHelper<any>();

export const signalDrawer: Array<Module<any>> = [
  {
    submodules: [
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.ID), {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Signal</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: qiqlAttributesIntoIdNameArray(data, SIGNALS_PATHS.CATALOG.LOCATIONS),
        technologies: qiqlAttributesIntoIdNameArray(data, SIGNALS_PATHS.CATALOG.TECHNOLOGIES),
        industry_sectors: qiqlAttributesIntoIdNameArray(data, SIGNALS_PATHS.CATALOG.INDUSTRY),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.TITLE), {
        title: 'Title',
      }),
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.TEXT), {
        title: 'AI Summary',
      }),
    ],
  },
  {
    direction: { base: 'column', s: 'row' },
    justify: 'space-around',
    gap: 20,
    submodules: [
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.CATALOG.CATEGORY, [], (c) => c[0]), {
        title: 'Category',
        align: 'center',
        element: ({ getValue, id }) => (
          <IconMap
            id={id}
            icons={CATEGORY_ICONS}
            map={{ [getValue()]: getValue() }}
            showTooltip
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.METATADA.INGESTED_AT), {
        title: 'Ingested At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.METATADA.PUBLISHED_AT), {
        title: 'Published At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.METATADA.UPDATED_AT), {
        title: 'Updated At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.METATADA.LINK), {
        title: 'Link',
      }),
      helper.accessor((data) => findInObject(data, SIGNALS_PATHS.CATALOG.SOURCE.NAME), {
        title: 'Source',
        element: ({ getValue }) => getValue(),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(findInObject(data, SIGNALS_PATHS.CATALOG.ALL)), {
        title: 'Extracted Entities',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
      }),
    ],
  },
];
