/* eslint-disable no-nested-ternary */
import type { ReactNode } from 'react';

import ThreatActor from '@/assets/icons/sidebar/threat-actor.svg';
import Malwares from '@/assets/icons/sidebar/malwares.svg';
import Attck from '@/assets/icons/sidebar/attck.svg';
import Sources from '@/assets/icons/sidebar/sources.svg';
import Providers from '@/assets/icons/sidebar/providers.svg';
import Personas from '@/assets/icons/sidebar/personas.svg';
import Vulnerabilities from '@/assets/icons/sidebar/vulnerabilities.svg';

/**
 * Count the number of items in each catalog
 * @param data
 * @returns
 */
const catalogs = [
  {
    name: ['threatactor', 'threat_actors'],
    display: 'Threat Actors',
    icon: <ThreatActor width={20} height={20} />,
  },
  {
    name: ['malwarekit', 'malware_kits'],
    display: 'Malware Kits',
    icon: <Malwares width={20} height={20} />,
  },
  {
    name: ['industrysector', 'industries', 'industry_sectors'],
    display: 'Industry Sectors',
  },
  {
    name: ['attck'],
    display: 'ATT&CK',
    icon: <Attck width={20} height={20} />,
  },
  {
    name: ['country'],
    display: 'Locations',
  },
  {
    name: ['persona', 'personas'],
    display: 'Personas',
    icon: <Personas width={20} height={20} />,
  },
  {
    name: ['provider', 'providers'],
    display: 'Providers',
    icon: <Providers width={20} height={20} />,
  },
  {
    name: ['company'],
    display: 'Companies',
  },
  {
    name: ['technology', 'technologies'],
    display: 'Technologies',
  },
  {
    name: ['vulnerability', 'vulnerabilities'],
    display: 'Vulnerabilities',
    icon: <Vulnerabilities width={20} height={20} />,
  },
  {
    name: ['source', 'sources'],
    display: 'Sources',
    icon: <Sources width={20} height={20} />,
  },
];

export const getContextData = <T extends Record<string, any>>(data: T, limit?: Array<keyof T>) => {
  const filteredCatalogs = limit
    ? catalogs.filter((catalog) => catalog.name.some((name) => limit.includes(name)))
    : catalogs;

  return filteredCatalogs.reduce((accu, curr) => {
    // Find the first matching catalog
    const context = curr.name.reduce((acc, name) => {
      if (data[name]) {
        return data[name];
      }

      return acc;
    }, undefined);

    const contextArr = Array.isArray(context)
      ? context
      : typeof context === 'object'
        ? Object.values(context ?? {})
        : [];

    if (!contextArr || (contextArr && !contextArr.length)) return accu;

    accu.push([curr.display, contextArr.length, curr.icon]);

    return accu;
  }, [] as Array<[string, number, ReactNode]>).sort((a, b) => b[1] - a[1]);
};
