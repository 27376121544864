import ModuleWrapper from './ModuleWrapper';
import MercuryTabs from '@/components/content/tabs/Tabs';

import type { ModuleDef } from '../types/modulesv2';

interface IssueTabsProps<TData> {
  module: ModuleDef<TData>;
}

const IssueTabs = <TData extends unknown>({ module: { title, submodules, id } }: IssueTabsProps<TData>) => (
  <ModuleWrapper
    // layout="list"
    title={title}
  >
    <MercuryTabs tabs={submodules.map((s) => ({ tab: s.title || '', val: s.title || '' }))} id={id} small>
      {submodules.map((submodule) => (submodule.render))}
    </MercuryTabs>
  </ModuleWrapper>
);

export default IssueTabs;
