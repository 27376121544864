import { deleteMutation, postMutation } from '@/lib/react-query/mutate';

import type { UserId, PostPutMethod } from '@/types/api';
import type { MutationOptions } from '@/types/react-query';

const comments = (issueId: number, userId: UserId, noteId?: number | null) => (
  `mercury/issues/comments/${issueId}/${userId}${noteId ? `/${noteId}` : ''}`
);

export const useIssueCommentsMutation = (
  method: PostPutMethod | 'DELETE',
  issueId: number,
  userId: UserId,
  mutationOptions: MutationOptions<unknown> = {},
  noteId?: number | null,
) => {
  if (method === 'DELETE') {
    return deleteMutation({
      endpoint: comments(issueId, userId, noteId),
      requestOptions: {
        error: 'There was an error with the form.',
      },
    });
  }

  return postMutation({
    endpoint: comments(issueId, userId, noteId),
    method,
    mutationOptions,
  });
};
