import { createRef, ReactNode, Ref } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Type
import type { NormalizedComment } from '../types';

import classes from './Comments.module.css';

interface CommentListProps<T> {
  comments: NormalizedComment<T>[];
  indent?: number;
  children: ({ comment }: { comment: NormalizedComment<T> }) => ReactNode;
}

const CommentList = <T extends Record<string, unknown>>({
  comments,
  indent = 1,
  children,
}: CommentListProps<T>) => (
  <TransitionGroup component="ul" className={classes.commentList} exit={false}>
    {comments.map((comment) => {
      const ref: Ref<HTMLLIElement> = createRef();

      return (
        <CSSTransition
          key={comment.id}
          timeout={500}
          nodeRef={ref}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive,
          }}
          className={classes.commentItem}
        >
          <li ref={ref}>
            {children({ comment })}

            <CommentList comments={comment.children || []} indent={indent + 1}>
              {children}
            </CommentList>
          </li>
        </CSSTransition>
      );
    })}
  </TransitionGroup>
  );

export default CommentList;
