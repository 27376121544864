import CommentList from '../../../comments/components/CommentList';
import CommentOptions from './CommentOptions';
import IssueComment from './IssueComment';
import Comments from '@/client/features/comments/Comments';

// API
import { useGetComments } from '../../services/getComments';

// Hooks
import { useComment } from '../../hooks/useComment';
import { Comment } from '../../types';
import { useAuth } from '@/hooks/useAuth';

interface CommentsProps {
  issueId: number;
  title: string;
}

const IssueComments = ({ issueId, title }: CommentsProps) => {
  const { userInfo } = useAuth();
  const { addComment, disableButtons, commentOptions, setCommentOption } = useComment({
    id: null,
    title,
    issueId,
    owner: null,
  });

  const { data: comments = [], isLoading, isError } = useGetComments(issueId, userInfo.user_id);

  return (
    <Comments
      onSubmit={addComment}
      disableButtons={disableButtons}
      commentOptions={<CommentOptions options={commentOptions} setOption={setCommentOption} />}
      minimalToolbar
    >
      {!isLoading && !isError && (
        <CommentList<Comment> comments={comments}>
          {({ comment }) => (
            <IssueComment
              id={comment.id}
              parent={comment.parent_id}
              issueId={issueId}
              title={title}
              comment={comment}
            />
          )}
        </CommentList>
      )}
    </Comments>
  );
};

export default IssueComments;
