import IocList from '../../issue/modules/IocList';
import IssueComments from '../../issue/components/comments/IssueComments';
import CatalogLinkList from '../../cataloglink/CatalogLinkList';
import TicketTable from '../../issue/components/TicketTable';
import Attachments, { getAttachments } from '../../issue/modules/Attachments';
import AlertsHistory from '../../issue/modules/AlertsHistory';
import Pill from '@/components/content/pills/Pill';
import TextWithAttachments from '../../issue/components/TextWithAttachments';
import TableActions from '../components/TableActions';
import IssueActions from '../components/IssueActions';
import EditorWrapper from '../../issue/components/EditorWrapper';

import { createHelper } from '@/client/features/issue/utils/helper';
import { Module, ModuleLayouts } from '../../issue/types/modulesv2';
import { toDate, toDateTime } from '@/utils/toDate';

import { incidentsStatusColorCoding } from '../utils';
import { mercuryTrackers } from '@/constants/mercuryPages';

import { malwareKitsDrawer } from '../../catalogs/features/malwares/pages/malwares.drawer';
import { threatActorDrawer } from '../../catalogs/features/threatactors/pages/threatactors.drawer';
import { sourcesDrawer } from '../../catalogs/features/sources/pages/sources.drawer';
import { incidentsKeys } from '../services/keys';

const helper = createHelper<any>();

interface Options {
  disallowedElements?: Array<string>;
  allowDrawer?: boolean;
  clamp?: boolean;
  hideActions?: boolean;
  table?: number;
  canEdit?: boolean;
}

export const reducedModules = ({
  disallowedElements,
  allowDrawer,
  table,
  clamp,
  canEdit,
  hideActions,
}: Options): Array<Module<any>> => [
  {
    id: 'overview',
    direction: { base: 'column', s: 'row' },
    wrap: 'wrap',
    align: { s: 'center' },
    submodules: [
      helper.accessor((data) => data.created_on, {
        title: 'Created:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        element: ({ getValue }) => toDate(getValue()),
        scroll: false,
      }),
      helper.accessor((data) => data.status, {
        title: 'Status:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        element: ({ getValue }) => (
          <Pill value={getValue()} component="span" color="var(--quo-white)" pillProps={incidentsStatusColorCoding} />
        ),
        scroll: false,
      }),
      helper.accessor((data) => data.priority_name, {
        title: 'Priority:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        scroll: false,
      }),
      helper.accessor((data) => data.drp_category, {
        title: 'Category:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        scroll: false,
      }),
      helper.accessor((data) => data.drp_subcategory, {
        title: 'Subcategory:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        scroll: false,
      }),
      helper.accessor((data) => data.source, {
        title: 'Source:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        element: ({ getValue }) => (
          allowDrawer ? (
            <CatalogLinkList
              value={getValue()}
              catalog="sources"
              content={sourcesDrawer}
            />
          ) : getValue()[0].name
        ),
        scroll: false,
      }),
      helper.accessor((data) => data.updated_on, {
        title: 'Last Update:',
        direction: { base: 'column', s: 'row' },
        align: 'center',
        element: ({ getValue }) => toDateTime(getValue()),
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.iocs, {
        title: 'IoC List',
        canCopy: true,
        element: ({ getValue }) => <IocList iocs={getValue()} />,
      }),
    ],
  },
  {
    removeBackground: true,
    hide: hideActions,
    submodules: [
      helper.accessor((data) => data, {
        title: 'Actions',
        direction: { base: 'column', s: 'row' },
        justify: 'space-between',
        element: ({ getValue, loading }) => {
          const { issue_id: id, status, extra } = getValue();

          if (table) {
            return (
              <TableActions
                issueId={id}
                status={status}
                asButton
              />
            );
          }

          return (
            <IssueActions
              issueId={id}
              extra={extra}
              loading={loading}
              status={status}
            />
          );
        },
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => (data.description ? { text: data.description, attachments: data.attachments } : null), {
        title: 'Description',
        element: ({ getValue, id }) => {
          const v = getValue();

          return (
            <EditorWrapper
              issueId={+id}
              queryKey={incidentsKeys.issue(+id)}
              initialContent={getValue()?.text}
              endpoint="v0/issue/incidents"
            >
              <TextWithAttachments
                value={clamp ? { text: v?.text.substring(0, 250), attachments: v?.attachments } : getValue()}
                issue={id}
                footnotesClass="description"
                disallowedElements={disallowedElements}
                textProps={{ lineClamp: clamp ? 5 : undefined }}
              />
            </EditorWrapper>
          );
        },
        canEdit,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Knowledge Base reference',
    layout: ModuleLayouts.TABS,
    submodules: [
      helper.accessor((data) => data.threat_actors, {
        title: 'Threat Actors',
        element: ({ getValue }) => (
          <CatalogLinkList
            value={getValue()}
            catalog="threat_actors"
            content={threatActorDrawer}
          />
        ),
      }),
      helper.accessor((data) => data.malware_kits, {
        title: 'Malware Kits',
        element: ({ getValue }) => (
          <CatalogLinkList
            value={getValue()}
            catalog="malware_kits"
            content={malwareKitsDrawer}
          />
        ),
      }),
    ],
  },
];

export const incidentModules = (options: Options) => [
  ...reducedModules(options),
  {
    title: 'Attachments',
    id: 'attachments',
    submodules: [
      helper.accessor(({ attachments, description }) => getAttachments({ attachments, text: [description] }), {
        element: ({ getValue, id }) => <Attachments attachments={getValue()} issue={id} />,
        scroll: false,

      }),
    ],
  },
  {
    title: 'Ticket Relationships',
    layout: ModuleLayouts.TABS,
    submodules: Object.values(mercuryTrackers).map((tracker) => (
      helper.accessor((data) => data.tickets_relationship[tracker.title], {
        title: tracker.title,
        element: ({ getValue, id }) => <TicketTable tickets={getValue()} issue={id} />,
      })
    )),
  },
  {
    removeBackground: true,
    hide: options.hideActions,
    submodules: [
      helper.accessor((data) => data, {
        title: 'Actions',
        direction: { base: 'column', s: 'row' },
        justify: 'space-between',
        element: ({ getValue, loading }) => {
          const { issue_id: id, status, extra } = getValue();

          return (
            <IssueActions
              issueId={id}
              extra={extra}
              loading={loading}
              status={status}
            />
          );
        },
        scroll: false,
      }),
    ],
  },
  {
    title: 'History',
    submodules: [
      helper.accessor((data) => data.id, {
        element: ({ getValue }) => <AlertsHistory value={getValue()} />,
      }),
    ],
  },
  {
    title: 'Comments',
    id: 'issue_comments',
    submodules: [
      helper.accessor((data) => ({ issueId: data.issue_id, title: data.subject || '' }), {
        element: ({ getValue }) => (
          <IssueComments {...getValue()} />
        ),
        scroll: false,
      }),
    ],
  },
];
