import { HoverCard, Stack } from '@mantine/core';
import type { Editor } from '@tiptap/react';

import MercuryButton from '../../button/Button';
import Tooltip from '../../tooltip/Tooltip';

import Table from '@/assets/icons/rte/table.svg';
import Trash from '@/assets/icons/content/trash.svg';
import Arrow from '@/assets/icons/content/right-arrow.svg';

import classes from '../RTE.module.css';

const RTETable = ({ editor }: { editor: Editor | null }) => {
  const isInTable = editor?.isActive('table');

  const tableActions = [
    {
      label: 'Delete Table',
      icon: <Trash />,
      action: () => editor?.chain().focus().deleteTable().run(),
    },
    {
      label: 'Insert Row After',
      icon: <Arrow />,
      action: () => editor?.chain().focus().addRowAfter().run(),
    },
    {
      label: 'Insert Row Before',
      icon: <Arrow className="rotate" />,
      action: () => editor?.chain().focus().addRowBefore().run(),
    },
    {
      label: 'Delete Row',
      icon: <Trash />,
      action: () => editor?.chain().focus().deleteRow().run(),
    },
    {
      label: 'Insert Column After',
      icon: <Arrow />,
      action: () => editor?.chain().focus().addColumnAfter().run(),
    },
    {
      label: 'Insert Column Before',
      icon: <Arrow className="rotate" />,
      action: () => editor?.chain().focus().addColumnBefore().run(),
    },
    {
      label: 'Delete Column',
      icon: <Trash />,
      action: () => editor?.chain().focus().deleteColumn().run(),
    },
  ];

  if (isInTable) {
    return (
      <HoverCard width="auto" shadow="md">
        <HoverCard.Target>
          <button className={classes.button} type="button" aria-label="Edit Table">
            <Table />
          </button>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack gap={5} align="flex-start">
            {tableActions.map((action) => (
              <MercuryButton
                classNames={{ section: classes.tableButton }}
                size="compact-xs"
                variant="outline"
                key={action.label}
                leftSection={action.icon}
                onClick={action.action}
              >
                {action.label}
              </MercuryButton>
            ))}
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }

  return (
    <Tooltip title="Insert Table">
      <button
        aria-label="Insert Table"
        className={classes.button}
        type="button"
        onClick={() => editor?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
      >
        <Table />
      </button>
    </Tooltip>
  );
};

export default RTETable;
