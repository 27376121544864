import { Group, Text } from '@mantine/core';

import BaseResult from '../../search/components/BaseResult';
import List from '@/components/content/list/List';
import IconMap from '../../iconmap/IconMap';
import Tooltip from '@/components/content/tooltip/Tooltip';
import IncidentModal from './IncidentModal';

import { toIsoDate } from '@/utils/toDate';
import { parseMetisString } from '@/utils/metisUtils';
import { getContextData } from '../../catalogs/utils/context';
import { getValues, objectHasValues } from '@/utils';

import { FLAG_ICONS } from '../../iconmap/icons/flags';
import { INCIDENT_CATEGORY_ICONS } from '../../iconmap/icons/incidents_category';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import type { MetisIncident } from '../types';

const IncidentSearchResult = ({ data }: { data: MetisIncident }) => {
  const flags = [
    ['takedowns', objectHasValues(data.takedowns)],
    ['iocs', data.iocs.length > 0],
  ].filter(([, value]) => value);

  const context = getContextData(data, ['threat_actors', 'malware_kits', 'vulnerabilities']);

  return (
    <BaseResult
      title={{ id: data.id, name: data.name }}
      content={data.description}
      tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
      customLink={(
        <IncidentModal
          incident={{
            ...data,
            issue_id: data.id,
            created_on: data.created_at,
            updated_on: data.updated_at,
            priority_name: data.priority,
            drp_category: data.category,
            drp_subcategory: data.subcategory,
            subject: data.name,
            status: null,
            source: getValues(data.sources).map((name) => ({ name })),
          }}
          inSearchResults
        />
      )}
      footer={[
        <Text size="sm">
          <Text fw="bold" span>Date:</Text> {toIsoDate(data.created_at)}
        </Text>,
        <Group gap={5} align="center">
          <Text fw="bold" size="sm" span>Category:</Text>
          <IconMap
            id={data.id}
            map={{ [data.subcategory]: data.subcategory }}
            icons={INCIDENT_CATEGORY_ICONS}
            layout="small"
            showTooltip
          />
        </Group>,
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip title={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
        flags.length > 0
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Additional Flags:</Text>
            <IconMap
              id={data.id}
              icons={FLAG_ICONS}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              map={Object.fromEntries(flags.map(([key]) => [key, key]))}
              tooltip={(t) => `Contains ${parseMetisString(t)}`}
              layout="small"
              showTooltip
            />
          </Group>
        ),
      ]}
    />
  );
};

export default IncidentSearchResult;
