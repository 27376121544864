import { Dispatch, memo, SetStateAction } from 'react';
import { ActionIcon, Box, Group } from '@mantine/core';

// Components
import CommentBox from './CommentBox';

// Types
import type { NormalizedComment, CommentActionsProps } from '../types';

// Styles
import Markdown from '@/components/content/markdown/Markdown';

// Misc
import { toDateTime } from '@/utils/toDate';

import TrashIcon from '@/assets/icons/content/trash.svg';
import EditIcon from '@/assets/icons/content/edit.svg';
import ReplyIcon from '@/assets/icons/content/reply.svg';

import classes from './Comments.module.css';

interface CommentProps<T> extends CommentActionsProps {
  comment: NormalizedComment<T>;
  formatDate?: (date: string) => string;
  minimalToolbar?: boolean;
}

const CommentComp = memo(
  <T extends Record<string, unknown>>({
    comment,
    onReply,
    onEdit,
    onRemove,
    disableButtons,
    commentOptions,
    allowEdit,
    allowReply,
    allowRemove,
    formatDate,
    setIsEditing,
    setIsReplying,
    isEditing,
    isReplying,
    minimalToolbar,
  }: CommentProps<T>) => {
    const getExtraButton = (cb: Dispatch<SetStateAction<boolean>>) => ({
      text: 'Cancel',
      disabled: false,
      background: 'var(--quo-power)',
      onClick: () => cb(false),
    });

    return (
      <>
        {isEditing && onEdit && (
          <div>
            <CommentBox
              comment={comment.comment}
              onSubmit={onEdit}
              name={`edit-comment-${comment.id}`}
              minimalToolbar={minimalToolbar}
              submitButton={{ text: 'Edit', disabled: !!disableButtons }}
              extraButton={getExtraButton((value) => {
                if (setIsReplying) setIsReplying(value);
                if (setIsEditing) setIsEditing(value);
              })}
            />
          </div>
        )}

        <article style={{ display: !isEditing ? 'block' : 'none' }}>
          <span className={classes.user}>{comment.username}</span>
          <small className={classes.date}>{formatDate ? formatDate(comment.date) : toDateTime(comment.date)}</small>

          <Box mt={15}>
            <Markdown text={comment.comment} />
          </Box>

          {(allowReply || allowEdit || allowRemove) && (
            <Group component="footer" gap={10} mt={10}>
              {allowReply && setIsReplying && (
                <ActionIcon
                  size={18}
                  variant="subtle"
                  aria-label="Reply Comment"
                  onClick={() => setIsReplying(true)}
                >
                  <ReplyIcon />
                </ActionIcon>
              )}
              {allowEdit && setIsEditing && (
                <ActionIcon
                  size={18}
                  variant="subtle"
                  aria-label="Edit Comment"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </ActionIcon>
              )}
              {allowRemove && onRemove && !comment.children.length && (
                <ActionIcon
                  size={18}
                  variant="subtle"
                  aria-label="Remove Comment"
                  onClick={onRemove}
                >
                  <TrashIcon />
                </ActionIcon>
              )}
            </Group>
          )}

          {isReplying && onReply && (
            <div className={classes.commentWrapper}>
              <CommentBox
                onSubmit={onReply}
                name={`reply-comment-${comment.id}`}
                minimalToolbar={minimalToolbar}
                submitButton={{ text: 'Reply', disabled: !!disableButtons }}
                extraButton={setIsReplying && getExtraButton(setIsReplying)}
                commentOptions={commentOptions}
              />
            </div>
          )}
        </article>
      </>
    );
  },
);

export default CommentComp;
