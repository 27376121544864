import { type QueryKey, useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';

export interface QueryOptions<TData, TSelectData> {
  endpoint: string;
  queryKey: QueryKey;
  select?: (data: TData) => TSelectData;
}

export const useGetDrawerData = <TData, TSelectData>(
  query: QueryOptions<TData, TSelectData>,
  enabled: boolean,
) => {
  const { queryKey, endpoint, select } = query;
  return useQuery({
    queryKey,
    queryFn: () => fetchData({ endpoint, context: { source: 'sidepanel' } }),
    select,
    enabled,
  });
};
