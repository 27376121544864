import { getMappedIconsFromImport } from '../utils/getIcons';

const categoryIconsImport = (require as any)
  .context('@/assets/icons/incident_categories', true, /\.svg$/);

const categoryIcons = getMappedIconsFromImport(categoryIconsImport);

export const INCIDENT_CATEGORY_ICONS: Record<string, any> = {
  'New domain registered': categoryIcons.new_domain_registered,
  'New hostname found': categoryIcons.new_hostname_found,
  'New email address': categoryIcons.new_email_address,
  'New SSL certificate registered for Lookalike domain': categoryIcons.new_ssl_certificate_found,
  'Page Impersonation': categoryIcons.impersonation_attempt,
  'Channel Impersonation': categoryIcons.impersonation_attempt,
  'E-Mail Impersonation': categoryIcons.impersonation_attempt,
  'Account Impersonation': categoryIcons.impersonation_attempt,
  'Confidential Information Leak': categoryIcons.credentials_leak,
  'Credentials leak': categoryIcons.credentials_leak,
  'Account Selling': categoryIcons.fraudulent_activity,
  'Personal ID Selling': categoryIcons.fraudulent_activity,
  'Personal Information Exposure': categoryIcons.personal_information_exposure,
  'New vulnerability': categoryIcons.new_vulnerability,
  'New vulnerable asset found': categoryIcons.new_vulnerable_asset_found,
  'Hacktivism activity': categoryIcons.underground_activity,
  'Underground activity': categoryIcons.underground_activity,
  'Potential Phishing Page': categoryIcons.potential_phishing,
  'Targeting Disclosure': categoryIcons.targeting_disclosure,
};
