import type { ChangeEvent } from 'react';

import { CheckboxStyled } from '@/components/content/form/components/Checkbox';

interface CommentOptionsProps {
  options: Record<string, unknown>
  setOption: (e: ChangeEvent<HTMLInputElement>) => void
}

const CommentOptions = ({ options, setOption }: CommentOptionsProps) => (
  <label>
    <CheckboxStyled
      type="checkbox"
      onChange={setOption}
      value={String(options.request_support)}
      name="request_support"
      id="request_support"
      checked={!!options.request_support}
    />
    Request Support
  </label>
);

export default CommentOptions;
