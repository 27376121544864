import type { PropsWithChildren } from 'react';
import { TypographyStylesProvider } from '@mantine/core';

import classes from './FullPageContent.module.css';

const FullPageContent = ({ children }: PropsWithChildren<{}>) => (
  <div className={classes.fullPageWrapper}>
    <TypographyStylesProvider classNames={{ root: classes.fullPageContent }}>
      {children}
    </TypographyStylesProvider>
  </div>
);

export default FullPageContent;
