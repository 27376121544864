import { type ChangeEvent, useCallback } from 'react';
import { NativeSelect } from '@mantine/core';
import type { Level } from '@tiptap/extension-heading';
import type { Editor } from '@tiptap/react';

import classes from '../RTE.module.css';

const RTEBlockSelect = ({ editor }: { editor: Editor | null }) => {
  const getValue = () => {
    if (editor?.isActive('blockquote')) {
      return 'blockquote';
    }

    if (editor?.isActive('heading')) {
      return String(editor?.getAttributes('heading').level);
    }

    return 'paragraph';
  };

  const options = [
    { value: '', label: 'Select Block Type', disabled: true },
    { value: 'paragraph', label: 'Paragraph' },
    { value: 'blockquote', label: 'Blockquote' },
    { value: '1', label: 'Heading 1' },
    { value: '2', label: 'Heading 2' },
    { value: '3', label: 'Heading 3' },
  ];

  const onChange = useCallback(({ target }: ChangeEvent<HTMLSelectElement>) => {
    const { value } = target;

    if (value === 'blockquote') {
      editor?.chain().focus().setParagraph().setBlockquote()
        .run();
      return;
    }

    if (value === 'paragraph') {
      editor?.chain().focus().setParagraph().run();
      return;
    }

    editor?.chain()
      .focus()
      .unsetCode()
      .unsetAllMarks()
      .setParagraph()
      .setHeading({ level: Number(value) as Level })
      .run();
  }, [editor]);

  return (
    <NativeSelect
      aria-placeholder="Block Type"
      value={getValue()}
      data={options}
      classNames={{ wrapper: classes.nativeSelect }}
      onChange={onChange}
      size="xs"
    />
  );
};

export default RTEBlockSelect;
