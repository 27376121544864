import { capitalize } from './string';

/**
 * Returns a string capitalized and parsed from metis standard
 * example: metis_response = Metis Response
 * example: metis__response = Metis & Response
 * @param str
 */
export function parseMetisString(str: string) {
  let parsed = str;

  parsed = parsed.replace(/__/g, ' & ');
  parsed = parsed.replace(/_/g, ' ');

  // Capitalize all words
  parsed = parsed.split(' ').map((word) => capitalize(word)).join(' ');

  return parsed;
}

function matchesMetisSpecialCharacters(str: string) {
  const symbols = /[,:/(){}<>""\s]/;
  return symbols.test(str);
}

export function stringifyMetisSpecialCharacters(str: string) {
  if (matchesMetisSpecialCharacters(str)) {
    return JSON.stringify(str);
  }

  return str;
}

export function parseMetisOrder(params: string) {
  if (!params) return '';

  const urlParams = new URLSearchParams(params);
  const order = urlParams.get('order');
  const direction = urlParams.get('order-dir');

  if (!order) return urlParams.toString();

  if (direction) {
    // Filter order and add negative in front of it
    urlParams.delete('order');
    urlParams.append('order', `${direction === 'desc' ? '-' : ''}${order}`);
    urlParams.delete('order-dir');
  }

  return urlParams.toString();
}
