import { EditorView } from '@codemirror/view';
import type { Extension } from '@codemirror/state';
import { HighlightStyle, syntaxHighlighting } from '@codemirror/language';
import { tags as t } from '@lezer/highlight';

export const defaultTheme = () => EditorView.theme(
  {
    '&.cm-focused .cm-cursor': { borderLeftColor: '#3b4252' },
    '&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection':
        { backgroundColor: 'rgba(202, 214, 217, 0.5)' },

    '.cm-activeLine': { backgroundColor: 'rgba(202, 214, 217, 0.5)' },
    '.cm-selectionMatch': { backgroundColor: 'rgba(202, 214, 217, 0.5)' },

    '.cm-scroller': { padding: '15px' },
  },
);

/// The highlighting style for code in the One Dark theme.
export const codeMirrorHighlight = () => HighlightStyle.define([
  { tag: t.strong, fontWeight: 'bold' },
  { tag: t.emphasis, fontStyle: 'italic' },
  { tag: t.strikethrough, textDecoration: 'line-through' },
  { tag: t.link, color: 'var(--quo-blue)', textDecoration: 'underline' },
  { tag: t.heading, fontWeight: 'bold', color: 'var(--quo-blue)' },

  { tag: [t.processingInstruction, t.string, t.inserted], color: 'var(--quo-power-50)' },
  { tag: t.invalid, color: 'var(--quo-power)' },
]);

export const theme = (): Extension => [
  defaultTheme(),
  syntaxHighlighting(codeMirrorHighlight()),
];
