import type { FetchError, UserId } from '@/types/api';
import type { MetadataEvent } from '../../metadata/types';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';
import { issuesKeys } from './keys';

const metadataeventshistory = (issueId: number, userId: UserId) => (
  `mercury/issues/${issueId}/metadata/events/${userId}`
);

export const useMetadataEventsHistoryInIssueQuery = (userId: UserId, issueId: number) => (
  useQuery<Array<MetadataEvent>, FetchError>({
    queryKey: issuesKeys.metadataHistory(issueId),
    queryFn: () => fetchData({ endpoint: metadataeventshistory(issueId, userId) }),
  })
);
