import { Button, Flex, Text, Stack } from '@mantine/core';

import DownloadIcon from '@/assets/icons/content/download.svg';
import { formatBytes } from '@/utils/number';

import { HOST } from '@/lib/fetch';

import classes from './DownloadLink.module.css';

export const downloadLink = (from: string, id: number) => `mercury/${from}/${id}`;

interface DownloadLinkProps {
  attachment: {
    id: number;
    filename: string;
    filesize?: number;
  };
  from: string;
  text?: string;
  button?: boolean;
}

const DownLoadLink = ({ attachment: { id, filename, filesize }, from, text, button }: DownloadLinkProps) => (
  <>
    {button ? (
      <Button
        color="var(--quo-power)"
        component="a"
        href={`${HOST}/${downloadLink(from, id)}`}
        download={filename}
        mt={15}
        fullWidth
      >
        {text || filename} {filesize && `(${formatBytes(Number(filesize))})`}
      </Button>
    ) : (
      <Flex
        component="a"
        align="center"
        gap={10}
        href={`${HOST}/${downloadLink(from, id)}`}
        aria-label={`Download ${filename} `}
        download={filename}
        classNames={{ root: classes.downloadLink }}
      >
        <DownloadIcon />

        <Stack gap={5}>
          <Text fw="bold" span>{filename}</Text>
          {filesize && (
            <Text component="small" size="xs">{formatBytes(Number(filesize))}</Text>
          )}
        </Stack>

      </Flex>
    )}
  </>
);

export default DownLoadLink;
