import { TypographyStylesProvider } from '@mantine/core';
import { Editor, EditorContent } from '@tiptap/react';
import type { FieldError } from 'react-hook-form';

// Styles
import classes from './RTE.module.css';

interface EditorProps {
  editor: Editor | null;
  error?: FieldError;
}

const EditorComp = ({
  editor,
  error,
}: EditorProps) => {
  const hasError = typeof error !== 'undefined';

  return (
    <>
      {editor && (
        <TypographyStylesProvider>
          <EditorContent
            className={`${classes.editor} ${hasError ? classes.error : ''}`}
            editor={editor}
          />
        </TypographyStylesProvider>
      )}
    </>
  );
};

export default EditorComp;
