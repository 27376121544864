import { Text } from '@mantine/core';

import BaseResult from '../../search/components/BaseResult';
import Pill from '@/components/content/pills/Pill';

import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';
import { toIsoDate } from '@/utils/toDate';
import { capitalize } from '@/utils/string';

import type { MetisRFI } from '../types';

const ServiceRequestsSearchResult = ({ data }: { data: MetisRFI }) => (
  <BaseResult
    title={{ id: data.id, name: data.name }}
    content={data.description}
    tracker={mercuryTrackers[MercuryTrackersIds.rfi]}
    footer={[
      <Text size="sm">
        <Text fw="bold" span>Date:</Text> {toIsoDate(data.created_at)}
      </Text>,
      <Text size="sm">
        <Text fw="bold" span>Status:</Text> <Pill value={capitalize(data.status)} component="span" />
      </Text>,
    ]}
  />
);

export default ServiceRequestsSearchResult;
