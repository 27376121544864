import { Box, Center, Text } from '@mantine/core';

import classes from './RiskGaude.module.css';

interface RiskGaugeProps {
  risk: number;
  priority: string;
}

const RiskGauge = ({ risk, priority }: RiskGaugeProps) => {
  if (priority === 'Unknown' || typeof risk !== 'number' || !risk) {
    return (
      <Center>
        <Text>Unknown Risk</Text>
      </Center>
    );
  }

  const gauge = Math.floor(risk * 10);
  const deg = (gauge * 90) / 50;

  return (
    <div className={classes.gauge}>
      <Box className={classes.percentageBar} style={{ '--percentage-deg': `${deg}deg` }}>
        <div className={classes.riskMask} />

        <div className={classes.riskContainer}>
          <span className={classes.riskValue}>{risk.toFixed(1)}</span>
          <span className={classes.priority}>{priority} RISK</span>
        </div>
      </Box>
    </div>
  );
};

export default RiskGauge;
