/* eslint-disable react/jsx-props-no-spreading */
// Components
import Tooltip from '@/components/content/tooltip/Tooltip';
import RedmineFieldList from '../catalogs/components/RedmineFieldList';
import DrawerLink from '../drawerlink/DrawerLink';
import DeferredDrawerLink from '../drawerlink/DeferredDrawerLink';
import SupportForm from '../mailboxes/SupportForm';

// Hooks
import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';

import { CATALOGS } from '@/constants/permissions';
import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from '../mailboxes/types';
import { metisKeys } from '@/services/keys';

import type { MetisCatalogCommonFields } from '@/types/mercury-data-types/metis';
import type { NormalizedCatalogListItem, NormalizedRedmineField } from '../catalogs/types';
import type { QuerySelect } from '@/types/react-query';
import type { Module } from '../issue/types/modulesv2';
import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';

interface CatalogLinkProps<TData, TSelect> {
  value: NormalizedRedmineField | NormalizedCatalogListItem | null;
  catalog: string;
  content: Array<Module<TSelect>>,
  select?: QuerySelect<TData, TSelect>,
  include?: Array<string>;
  link?: string;
}

const CatalogLink = <TData extends unknown, TSelectData extends MetisCatalogCommonFields>({
  name,
  disabled,
  id,
  catalog,
  content,
  select,
  link,
  include,
}: NormalizedCatalogListItem & Omit<CatalogLinkProps<TData, TSelectData>, 'value'>) => {
  const parsedCatalog = catalog.replace('_', '') as keyof typeof CATALOGS;
  const catalogPage = mercuryTrackers[MercuryTrackersIds[parsedCatalog]].page;
  const requiredModules = CATALOGS[parsedCatalog].modules;
  const { hasAccess } = useMercuryPermissions();
  const canAccess = hasAccess({ modules: requiredModules });
  const endpoint = `v0/catalog/${catalog}/${id}?include=raw_intel${include ? `,${include.join(',')}` : ''}`;

  if (disabled) {
    return name;
  }

  if (!canAccess) {
    return (
      <Tooltip title="You need a premium subscription to see this content">
        <DrawerLink
          id={id}
          title="Request access to premium module"
          value={name}
          content={(
            <SupportForm
              disabledFields={{ subject: true }}
              name="support-form"
              prefillData={{
                subject: SUPPORT_SUBJECTS.PREMIUM_TRIAL,
                description: 'I would like to request access to the premium module',
                to: SUPPORT_TYPES.SUPPORT,
              }}
              subjects={[SUPPORT_SUBJECTS.PREMIUM_TRIAL]}
            />
          )}
        />
      </Tooltip>
    );
  }

  return (
    <DeferredDrawerLink
      value={name}
      query={{
        queryKey: metisKeys.records(endpoint),
        endpoint,
        select,
      }}
      link={`/catalogs/${link || catalogPage}/entity/${id}`}
      content={content}
      id={id}
    />
  );
};

const CatalogLinkList = <TData extends unknown, TSelectData extends MetisCatalogCommonFields>({
  value,
  ...rest
}: CatalogLinkProps<TData, TSelectData>) => {
  if (!value) return null;

  if (!Array.isArray(value)) {
    return <CatalogLink {...value} {...rest} />;
  }

  return (
    <RedmineFieldList
      items={value}
      renderItem={({ name, ...item }) => (
        <CatalogLink
          name={`${name} ${item.amount ? `(${item.amount})` : ''}`}
          {...item}
          {...rest}
        />
      )}
    />
  );
};

export default CatalogLinkList;
