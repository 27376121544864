import { createColumnHelper } from '@tanstack/react-table';
import IssueIDLink from '../../issueIdLink/IssueIdLink';

import { toDate } from '@/utils/toDate';

import type { Ticket } from '@/types/mercury-data-types';
import { mercuryTrackers, MercuryTrackersIds } from '@/constants/mercuryPages';

const columnHelper = createColumnHelper<Ticket>();

export const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    cell: ({ getValue, row }) => {
      const tracker = row.original.drp_subcategory === 'New vulnerability'
        ? MercuryTrackersIds.vulnerabilities
        : row.original.tracker_id;

      return (
        <IssueIDLink
          tracker={mercuryTrackers[tracker]}
          value={getValue()}
          useParent
        />
      );
    },
    size: 90,
    meta: {
      alignment: 'numeric',
    },
  }),
  columnHelper.accessor('subject', {
    meta: {
      noSize: true,
    },
  }),
  columnHelper.accessor('updated_on', {
    cell: ({ getValue }) => toDate(getValue()),
    header: 'Last Updated',
    size: 150,
  }),
  columnHelper.accessor('status_text', {
    header: 'Status',
    size: 180,
  }),
];
