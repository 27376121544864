import { getMappedIconsFromImport } from '../utils/getIcons';

const categoryIconsImport = (require as any)
  .context('@/assets/icons/intel_categories', true, /\.svg$/);

const categoryIcons = getMappedIconsFromImport(categoryIconsImport);

export const CATEGORY_ICONS: Record<string, any> = {
  'Attacks & Campaigns': categoryIcons.attacks_campaigns,
  'Cyber Incident': categoryIcons.cyber_incident,
  Demonstrations: categoryIcons.demonstrations,
  'Diplomatic Tensions': categoryIcons.diplomatic_tensions,
  Disinformation: categoryIcons.disinformation,
  'Economic Indicators': categoryIcons.economic_indicators,
  Elections: categoryIcons.elections,
  'Emerging Technology': categoryIcons.emerging_technology,
  'Emerging Threats': categoryIcons.emerging_threats,
  'Exploit Code': categoryIcons.exploit_code,
  'Malware & Tools Analysis': categoryIcons.malware_tools_analysis,
  Military: categoryIcons.military,
  'Natural Hazards': categoryIcons.natural_hazards,
  'Physical Threat': categoryIcons.physical_threat,
  'Policy & Compliance': categoryIcons.policy_compliance,
  'Political Meeting': categoryIcons.political_meeting,
  'Product Updates': categoryIcons.product_updates,
  Sanctions: categoryIcons.sanctions,
  'Social Event': categoryIcons.social_event,
  'Threat Disruption': categoryIcons.threat_disruption,
  'Trade Agreements': categoryIcons.trade_agreements,
  'Trend Report': categoryIcons.trend_report,
  'Underground Activity': categoryIcons.underground_activity,
  Vulnerability: categoryIcons.vulnerability,
};
