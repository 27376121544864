/* eslint-disable import/no-extraneous-dependencies */

import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import History from '@tiptap/extension-history';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Table from '@tiptap/extension-table';
import Gapcursor from '@tiptap/extension-gapcursor';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Blockquote from '@tiptap/extension-blockquote';
import Heading from '@tiptap/extension-heading';
import Dropcursor from '@tiptap/extension-dropcursor';

import { Image } from './RTEImage';

export const extensions = [
  Document,
  Gapcursor,
  History,
  Paragraph,
  Text,
  Bold,
  Underline,
  HardBreak,
  Italic,
  Dropcursor,
  BulletList,
  OrderedList,
  ListItem,
  Image,
  Code,
  CodeBlock,
  Blockquote,
  Heading,
  Table.configure({
    resizable: false,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Placeholder.configure({
    placeholder: 'Write a comment...',
  }),
  Link.configure({
    openOnClick: false,
  }),
];
