import { Flex, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import { NotificationsType } from '../types';

import classes from '../Notifications.module.css';

interface NotificationProps {
  items: Array<NotificationsType>,
  getNotification: (type: NotificationsType) => number,
  getItem: (type: NotificationsType) => ({ title: string; path: string }),
  total: number,
  emptyMsg: string,
  footer?: JSX.Element | null;
}

const Notification = ({ items, getNotification, getItem, total, emptyMsg, footer: Footer }: NotificationProps) => (
  <>
    <ul>
      {
        items.map((type) => {
          const notification = getNotification(type);

          return notification > 0 ? (
            <li className={classes.menuItem} key={type}>
              <Flex direction="row" align="center" p={10} component={Link} to={getItem(type).path}>
                <span><b>{getItem(type).title}</b></span>

                <Text
                  size="sm"
                  classNames={{ root: classes.menuItemtext }}
                  span
                >
                  {notification}
                </Text>
              </Flex>
            </li>
          ) : null;
        })
      }
    </ul>

    {Footer}

    {total === 0 && (<Text size="lg" c="var(--quo-grey-50)" ta="center">No {emptyMsg} notifications</Text>)}
  </>
);

export default Notification;
