const BASE_PATHS = {
  COMMON: 'common',
  STRUCTURED: 'data.structured',
  CATALOG: 'catalog',
  METADATA: 'metadata',
} as const;

export const SIGNALS_PATHS = {
  ID: [
    'signal_id',
  ],
  TITLE: [
    `${BASE_PATHS.COMMON}.title`,
    `${BASE_PATHS.STRUCTURED}.title`,
  ],
  TEXT: [
    `${BASE_PATHS.COMMON}.quo_summary`,
    `${BASE_PATHS.COMMON}.summary`,
    `${BASE_PATHS.STRUCTURED}.content.summary`,
    `${BASE_PATHS.STRUCTURED}.content.text`,
    `${BASE_PATHS.STRUCTURED}.Text`,
  ],
  TIMESTAMP: [
    `${BASE_PATHS.COMMON}.timestamp`,
    `${BASE_PATHS.STRUCTURED}.timestamp`,
    '@timestamp',
  ],
  METATADA: {
    PUBLISHED_AT: [
      `${BASE_PATHS.METADATA}.published_at`,
    ],
    UPDATED_AT: [
      `${BASE_PATHS.METADATA}.updated_at`,
    ],
    INGESTED_AT: [
      `${BASE_PATHS.METADATA}.ingested_at`,
    ],
    LINK: [
      `${BASE_PATHS.METADATA}.link`,
    ],
  },
  CATALOG: {
    ALL: [
      'catalog',
    ],
    CATEGORY: [
      `${BASE_PATHS.CATALOG}.category`,
    ],
    SOURCE: {
      ID: [
        `${BASE_PATHS.CATALOG}.source_id`,
      ],
      NAME: [
        `${BASE_PATHS.CATALOG}.source`,
        `${BASE_PATHS.METADATA}.collector.name`,
      ],
    },
    TYPE: [
      `${BASE_PATHS.CATALOG}.type`,
    ],
    INDUSTRY: {
      ID: [
        `${BASE_PATHS.CATALOG}.industrysector_id`,
      ],
      NAME: [
        `${BASE_PATHS.CATALOG}.industrysector`,
      ],
    },
    TECHNOLOGIES: {
      ID: [
        `${BASE_PATHS.CATALOG}.technology_id`,
      ],
      NAME: [
        `${BASE_PATHS.CATALOG}.technology`,
      ],
    },
    LOCATIONS: {
      ID: [
        `${BASE_PATHS.CATALOG}.country_id`,
      ],
      NAME: [
        `${BASE_PATHS.CATALOG}.country`,
      ],
    },
  },
};
