/* eslint-disable  no-restricted-syntax */
export const deepArrFind = <TC extends string, T extends Record<string, unknown> & { [key in TC]: T[] }>(
  array: T[],
  id: number,
  children: TC,
): T | null => {
  for (const item of array) {
    if (item.id === id) return item;
    if (item[children] && item[children].length) {
      const innerResult = deepArrFind(item[children], id, children);

      if (innerResult) return innerResult;
    }
  }

  return null;
};
