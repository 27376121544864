/* eslint-disable react/no-array-index-key */
import { Box, Grid } from '@mantine/core';

import Title from '@/components/content/title/Title';
import ModuleWrapper from './ModuleWrapper';
import Submodule from './Submodule';

import type { SubmoduleDef } from '../types/modulesv2';

import classes from './IssueModule.module.css';
import { EditContextProvider } from '../context/EditContext';

interface IssueGridProps<TData> {
  title?: string;
  submodules: Array<SubmoduleDef<TData>>;
}

const IssueGrid = <TData extends unknown>({ title, submodules }: IssueGridProps<TData>) => (
  <>
    <Box mt={25}>
      <Title order={2}>{title}</Title>
    </Box>

    <Grid columns={6} classNames={{ root: classes.grid }} grow>
      {submodules.map((submodule, i) => (
        <Grid.Col key={`grid-submodule-${i}-${title}`} span={{ base: 6, xs: 3, md: 6, xl: 3 }}>
          <ModuleWrapper expand>
            <EditContextProvider>
              <Submodule submodule={submodule} />
            </EditContextProvider>
          </ModuleWrapper>
        </Grid.Col>
      ))}
    </Grid>
  </>
);

export default IssueGrid;
