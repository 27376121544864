import { useRef } from 'react';
import { ActionIcon, Flex } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';

import Tooltip from '@/components/content/tooltip/Tooltip';

import type { SubmoduleDef } from '../types/modulesv2';

import CopyIcon from '@/assets/icons/content/copy.svg';
import EditIcon from '@/assets/icons/content/edit.svg';

import classes from './IssueModule.module.css';
import { useEdit } from '../context/EditContext';

interface SubmoduleProps<TData> {
  submodule: SubmoduleDef<TData>;
  gap?: number;
  justify?: string;
}

const Submodule = <TData extends unknown>({
  submodule,
  gap = 5,
}: SubmoduleProps<TData>) => {
  const clipboard = useClipboard({ timeout: 1000 });
  const moduleContentRef = useRef<HTMLDivElement>(null);
  const { isEditing, setIsEditing, allowedEdit } = useEdit();

  const {
    accessor,
    element,
    title,
    canCopy,
    canEdit,
    scroll,
    render,
    direction = 'column',
    justify = 'start',
    ...flexProps
  } = submodule;

  const onClickCopy = () => {
    const text = moduleContentRef?.current?.innerText;
    clipboard.copy(String(text));
  };

  return (
    <Flex
      gap={gap}
      direction={direction}
      justify={justify}
      {...flexProps}
    >
      {title && (
        <h3 className={classes.submoduleTitle}>
          <span>
            {title}
          </span>

          {canCopy && (
            clipboard.copied
              ? <span>copied!</span>
              : (
                <Tooltip title="Copy">
                  <button type="button" onClick={onClickCopy}>
                    <CopyIcon>Copy</CopyIcon>
                  </button>
                </Tooltip>
              )
          )}

          {canEdit && allowedEdit && (
            <Tooltip title={isEditing ? 'Cancel' : 'Edit'}>
              <ActionIcon
                size={20}
                variant="subtle"
                aria-label="Edit"
                onClick={() => setIsEditing((editing) => !editing)}
              >
                <EditIcon />
              </ActionIcon>
            </Tooltip>
          )}
        </h3>
      )}

      <div
        className={`${classes.submoduleContent} ${scroll ? classes.scroll : ''} content`}
        ref={moduleContentRef}
      >
        {render}
      </div>
    </Flex>
  );
};

export default Submodule;
