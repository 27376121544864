import type { PropsWithChildren, ReactNode } from 'react';
import { Editor } from '@tiptap/react';
import { Box, Flex, Group } from '@mantine/core';

// Components
import MarkButton, { ToolbarButton } from './RTEMarkButton';
import RTEBlockSelect from './RTEBlockSelect';
import RTETable from './RTETable';
import Tooltip from '../../tooltip/Tooltip';

import { capitalize } from '@/utils/string';

// Icons
import Bold from '@/assets/icons/rte/bold.svg';
import Italic from '@/assets/icons/rte/italic.svg';
import Underline from '@/assets/icons/rte/underline.svg';
import Olist from '@/assets/icons/rte/ol.svg';
import Ulist from '@/assets/icons/rte/ul.svg';
import Link from '@/assets/icons/rte/link.svg';
import Unlink from '@/assets/icons/rte/unlink.svg';
import Code from '@/assets/icons/rte/code.svg';
import CodeBlock from '@/assets/icons/rte/codeblock.svg';

import classes from '../RTE.module.css';

const defaultOptions: Array<ToolbarButton & { label?: string; }> = [
  { icon: Bold, format: 'bold' },
  { icon: Italic, format: 'italic' },
  { icon: Underline, format: 'underline' },
  { icon: Code, format: 'code', label: 'Inline Code' },
  { icon: Link, type: 'link', format: 'link' },
  { icon: Unlink, type: 'unlink', format: 'unlink' },
  { icon: Olist, type: 'list', format: 'number', label: 'Ordered List' },
  { icon: Ulist, type: 'list', format: 'bullet', label: 'Bullet List' },
];

interface ToolbarProps {
  editor: Editor | null;
  extraOptions?: ReactNode;
  minimalToolbar?: boolean;
}

const Toolbar = ({ editor, extraOptions, minimalToolbar }: PropsWithChildren<ToolbarProps>) => (
  <Flex
    direction={{ base: 'column', sm: 'row' }}
    justify={{ base: 'center', sm: 'space-between' }}
    align={{ sm: 'center' }}
    mt={0}
    gap={15}
  >
    <Group gap={15} component="ul">
      {defaultOptions.map((button) => (
        <Tooltip title={button.label ?? capitalize(button.format)} key={`${button.format}`}>
          <Box mt={0} component="li" h={20}>
            <MarkButton
              editor={editor}
              type={button.type || 'mark'}
              icon={button.icon}
              format={button.format}
            />
          </Box>
        </Tooltip>
      ))}

      {!minimalToolbar && (
        <>
          <Box mt={0} component="li" h={20} key="table">
            <RTETable editor={editor} />
          </Box>

          <Box mt={0} component="li" h={20} key="codeblock">
            <Tooltip title="Preformatted Text">
              <button
                aria-label="Insert Table"
                className={`${classes.button} ${editor?.isActive('codeBlock') ? classes.active : ''}`}
                type="button"
                onClick={() => editor?.chain().focus().toggleCodeBlock().run()}
              >
                <CodeBlock />
              </button>
            </Tooltip>
          </Box>

          <Box mt={0} component="li" key="quote">
            <RTEBlockSelect editor={editor} />
          </Box>
        </>
      )}
    </Group>

    {extraOptions}
  </Flex>
);

export default Toolbar;
