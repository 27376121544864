import { findInObject } from './findInObject';

const nameMapping: Record<string, string> = {
  threat_actors: 'threatactors',
  new_victim: 'new_victim',
  interval: 'interval',
};

function getTimeframeGroupBy(from: Date, to: Date): 'month' | 'week' | 'day' {
  const timeframeDays = (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24);

  if (timeframeDays <= 30) {
    return 'day';
  }

  if (timeframeDays <= 90) {
    return 'week';
  }

  return 'month';
}

export const qiqlQueryAdapter = (params: string) => {
  const decodedParams = decodeURIComponent(params);
  const mappedParams = decodedParams.replace(/filter\[([^\]]+)\]=/g, (_, p1) => `${nameMapping[p1] ?? p1}=`);
  const intervalFilter = mappedParams.match(/interval=([^&]+)/)?.[1];

  // contains an object with the from and to dates in a Date object format taken from the interval filter
  const [from, to] = intervalFilter?.split('/') ?? [];

  const interval = {
    from: new Date(from ?? ''),
    to: new Date(to ?? ''),
  };

  return `${mappedParams}&group-by=${getTimeframeGroupBy(interval.from, interval.to)}`;
};

export const qiqlAttributesIntoIdNameArray = (data: any, path: any): Array<{ id: number; name: string }> => {
  const ids = findInObject(data, path.ID, []);
  const names = findInObject(data, path.NAME, []);

  return ids.map((id: any, index: number) => ({
    id,
    name: names[index],
  }));
};
