import { useUserId } from '@/hooks/useUserId';
import { postMutation } from '@/lib/react-query/mutate';

import type { MutationOptions } from '@/types/react-query';

export const METADATA_TYPE = {
  LIKE: 'like_it',
  ACKNOWLEDGED: 'acknowledged',
  RESOLVED: 'resolved',
} as const;

export const METADATA_NAME = {
  ...METADATA_TYPE,
  LIKE: 'likeIt',
} as const;

export type MetadataType = typeof METADATA_TYPE[keyof typeof METADATA_TYPE];
export type MetadataName = typeof METADATA_NAME[keyof typeof METADATA_NAME];

export const useMetadata = (
  issueId: number,
  type: MetadataType,
  value: boolean,
  mutationOptions?: MutationOptions<unknown>,
) => {
  const userId = useUserId();
  const params = `${type}=${value ? 'false' : 'true'}`;

  return postMutation({
    endpoint: `mercury/issues/${issueId}/metadata${type === METADATA_TYPE.LIKE ? '' : '/events'}/${userId}?${params}`,
    mutationOptions,
  });
};
