import type { QueryKey } from '@tanstack/react-query';

import Markdown, { MarkdownProps } from '@/components/content/markdown/Markdown';

import { downloadLink } from '@/features/downloadlink/DownLoadLink';
import { useUserId } from '@/hooks/useUserId';
import { HOST } from '@/lib/fetch';

import type { Attachment } from '../types';
import type { PlainObject } from '@/types/general';

interface TextWithAttachmentsProps extends Omit<MarkdownProps, 'text'> {
  value: {
    text: string;
    attachments: Array<Attachment>;
  } | null;
  issue: string | number;
  editQueryKey?: QueryKey;
}

const TextWithAttachments = ({
  value,
  issue,
  editQueryKey,
  ...markdownProps
}: TextWithAttachmentsProps) => {
  const userId = useUserId();

  if (!value) return null;

  const { text, attachments } = value;

  if (!attachments) {
    return (
      <Markdown
        text={text}
        {...markdownProps}
      />
    );
  }

  const mapAttachments = attachments.reduce<PlainObject<number>>((accu, curr) => {
    accu[curr.filename.toLowerCase()] = curr.id;
    return accu;
  }, {});

  const from = `attachment/${userId}/${issue}`;

  return (
    <Markdown
      {...markdownProps}
      text={text}
      transformImageUri={(src) => {
        let id;

        try {
          id = mapAttachments[decodeURIComponent(src.toLowerCase())];
        } catch {
          return src;
        }

        return id ? `${HOST}/${downloadLink(from, id)}` : src;
      }}
    />
  );
};

export default TextWithAttachments;
