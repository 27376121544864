import { getValues } from '@/utils';
import { useAuth } from './useAuth';

export interface UseMSSPReturn {
  isMSSP: boolean;
  isSubclient: boolean;
  subclients: Array<string>;
}

export const useMSSP = (): UseMSSPReturn => {
  const auth = useAuth();

  const subclients = getValues(auth.userInfo.subclients ?? []);
  const isMSSP = subclients.length > 0;
  const isSubclient = auth.userInfo.is_subclient;

  return { isMSSP, isSubclient, subclients };
};
