import { getMappedIconsFromImport } from '../utils/getIcons';

const flagIconsImport = (require as any).context('@/assets/icons/flags', true, /\.svg$/);

const flagIcons = getMappedIconsFromImport(flagIconsImport);

export const FLAG_ICONS: Record<string, any> = {
  iocs: flagIcons.iocs,
  analyst_note: flagIcons.analyst_note,
  takedowns: flagIcons.takedowns,
};
